import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, fetchUserData } from '../action/universityActions';
import Logo from '../assets/logo.png';
import './style.scss';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const universitiesState = useSelector((state) => state.universities);
  const role = universitiesState?.user?.role;
  const userName = localStorage.getItem('username');
  const roleName = localStorage.getItem('role')


  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <header className="header">
      <img src={Logo} alt="Logo" />
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <span style={{ textTransform: 'uppercase' }}>{userName}</span>
        <Link to="#" className="logout-button" onClick={handleLogout}>LOG OUT</Link>
      </div>
    </header>
  );
};

export default Header;
