import React from 'react';
import { resetState } from "../action/universityActions";
import { useDispatch } from 'react-redux';
import './common.scss';

const SyncModal = ({ isOpen, onClose, step, progress, statusMessage }) => {
    const dispatch = useDispatch();
    if (!isOpen) return null;

    const handleSyncClick = () => {
        dispatch(resetState());
        window.location.reload();
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                {step === 1 && (
                    <div className="sync-modal">
                        <h2>Marks Syncing</h2>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${progress}%` }}></div>
                        </div>
                        <p>{progress}%</p>
                    </div>
                )}
                {step === 2 && (
                    <div className="second-modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <circle cx="20" cy="20" r="20" fill="#56AB91" />
                            <mask id="mask0_374_3897" maskUnits="userSpaceOnUse" x="3" y="4" width="33" height="33">
                                <rect x="4.5" y="5.5" width="30" height="30" fill="#D9D9D9" stroke="white" strokeWidth="3" />
                            </mask>
                            <g mask="url(#mask0_374_3897)">
                                <mask id="path-3-inside-1_374_3897" fill="white">
                                    <path d="M16.1314 28.7531L8.29395 20.9156L10.2533 18.9563L16.1314 24.8344L28.7471 12.2188L30.7064 14.1781L16.1314 28.7531Z" />
                                </mask>
                                <path d="M16.1314 28.7531L8.29395 20.9156L10.2533 18.9563L16.1314 24.8344L28.7471 12.2188L30.7064 14.1781L16.1314 28.7531Z" fill="white" />
                                <path d="M16.1314 28.7531L14.0101 30.8744L16.1314 32.9958L18.2528 30.8744L16.1314 28.7531ZM8.29395 20.9156L6.17262 18.7943L4.0513 20.9156L6.17263 23.0369L8.29395 20.9156ZM10.2533 18.9563L12.3746 16.8349L10.2533 14.7136L8.132 16.8349L10.2533 18.9563ZM16.1314 24.8344L14.0101 26.9557L16.1314 29.077L18.2528 26.9557L16.1314 24.8344ZM28.7471 12.2188L30.8684 10.0974L28.7471 7.97611L26.6258 10.0974L28.7471 12.2188ZM30.7064 14.1781L32.8278 16.2994L34.9491 14.1781L32.8278 12.0568L30.7064 14.1781ZM18.2528 26.6318L10.4153 18.7943L6.17263 23.0369L14.0101 30.8744L18.2528 26.6318ZM10.4153 23.0369L12.3746 21.0776L8.132 16.8349L6.17262 18.7943L10.4153 23.0369ZM8.132 21.0776L14.0101 26.9557L18.2528 22.7131L12.3746 16.8349L8.132 21.0776ZM18.2528 26.9557L30.8684 14.3401L26.6258 10.0974L14.0101 22.7131L18.2528 26.9557ZM26.6258 14.3401L28.5851 16.2994L32.8278 12.0568L30.8684 10.0974L26.6258 14.3401ZM28.5851 12.0568L14.0101 26.6318L18.2528 30.8744L32.8278 16.2994L28.5851 12.0568Z" fill="white" mask="url(#path-3-inside-1_374_3897)" />
                            </g>
                        </svg>
                        <h2>Data fetched & stored successfully.</h2>
                        <div className="sync-button" style={{ display: 'flex', gap: '50px', marginTop: '60px' }}>
                            <button onClick={onClose} className="sync-button">Close</button>
                            <button onClick={handleSyncClick} className="sync-another">Sync Another</button>
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className="third-modal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 100 100" fill="none">
                            <circle cx="50" cy="50" r="37.5" fill="#FF0000" />
                            <line x1="35" y1="35" x2="65" y2="65" stroke="#ECF6F2" strokeWidth="6" strokeLinecap="round" />
                            <line x1="65" y1="35" x2="35" y2="65" stroke="#ECF6F2" strokeWidth="6" strokeLinecap="round" />
                        </svg>
                        <h2 style={{ color: 'red' }}>{statusMessage}</h2>
                        <div className="sync-button">
                            <button onClick={onClose} className="sync-button">Close</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SyncModal;
