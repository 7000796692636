import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomeSection from './component/HomeSection';
import SignInPage from "./component/SignInPage"

const App = () => {
  return (
    <Router>
      <div className="app-container" style={{position:'fixed', width:'100%'}}>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<SignInPage />} />
            <Route path="/dashboard" element={<HomeSection />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
