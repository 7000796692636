// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import universitiesReducer from '../reducers/universitiesReducer';
import camsReucer from "../reducers/camsReducer";

const rootReducer = combineReducers({
  universities: universitiesReducer,
  cams:camsReucer
});

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;