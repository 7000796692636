import {
    FETCH_UNIVERSITIES_REQUEST,
    FETCH_UNIVERSITIES_SUCCESS,
    FETCH_UNIVERSITIES_FAILURE,
    FETCH_COURSE_CATEGORIES_REQUEST,
    FETCH_COURSE_CATEGORIES_SUCCESS,
    FETCH_COURSE_CATEGORIES_FAILURE,
    FETCH_COURSES_REQUEST_CAMS,
    FETCH_COURSES_SUCCESS_CAMS,
    FETCH_COURSES_FAILURE_CAMS,
    FETCH_CAMS_RESOURCES_REQUEST,
    FETCH_CAMS_RESOURCES_SUCCESS,
    FETCH_CAMS_RESOURCES_FAILURE,
    FETCH_GRADES_REQUEST_CAM,
    FETCH_GRADES_SUCCESS_CAM,
    FETCH_GRADES_FAILURE_CAM,
    FETCH_CAMS_DETAILED_MARKS_REQUEST,
    FETCH_CAMS_DETAILED_MARKS_SUCCESS,
    FETCH_CAMS_DETAILED_MARKS_FAILURE,
    FETCH_QUIZ_REPORT_REQUEST,
    FETCH_QUIZ_REPORT_SUCCESS,
    FETCH_QUIZ_REPORT_FAILURE,
    POST_QUIZ_REPORT_REQUEST_CAMS,
    POST_QUIZ_REPORT_SUCCESS_CAMS,
    POST_QUIZ_REPORT_FAILURE_CAMS,
    FETCH_STATUS_REQUEST_CAM,
    FETCH_STATUS_SUCCESS_CAM,
    FETCH_STATUS_FAILURE_CAM,
    FETCH_CAMS_SEND_TO_UMS_REQUEST,
    FETCH_CAMS_SEND_TO_UMS_SUCCESS,
    FETCH_CAMS_SEND_TO_UMS_FAILURE,
    CAMS_UNLOCK_DATA_REQUEST,
    CAMS_UNLOCK_DATA_SUCCESS,
    CAMS_UNLOCK_DATA_FAILURE,
    CAMS_ATTENDANCE_REQUEST,
    CAMS_ATTENDANCE_SUCCESS,
    CAMS_ATTENDANCE_FAILURE,
    FETCH_CAMS_SEND_TO_UNS_REQUEST,
    FETCH_CAMS_SEND_TO_UNS_SUCCESS,
    FETCH_CAMS_SEND_TO_UNS_FAILURE,
    RESET_STATE

} from "../action/actionType";

const initialState = {
    loading: false,
    universitiesCAMS: null,
    courseCategories: null,
    courses: null,
    resources: null,
    camGrades: null,
    camViewResult: null,
    report: null,
    quizReports: null,
    status: null,
    sendToCamsUMS: null,
    lock: null,
    isFetching: false,
    attendance: null,
    sendToUni: null
};

const universitiesReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_UNIVERSITIES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_UNIVERSITIES_SUCCESS:
            return {
                ...state,
                loading: false,
                universitiesCAMS: action.payload,
                error: ''
            };
        case FETCH_UNIVERSITIES_FAILURE:
            return {
                ...state,
                loading: false,
                universitiesCAMS: [],
                error: action.payload
            };
        case FETCH_COURSE_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_COURSE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                courseCategories: action.payload,
                error: ''
            };
        case FETCH_COURSE_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                courseCategories: [],
                error: action.payload
            };
        case FETCH_COURSES_REQUEST_CAMS:
            return {
                ...state,
                loading: true
            };
        case FETCH_COURSES_SUCCESS_CAMS:
            return {
                ...state,
                loading: false,
                courses: action.payload,
                error: ''
            };
        case FETCH_COURSES_FAILURE_CAMS:
            return {
                ...state,
                loading: false,
                courses: [],
                error: action.payload
            };
        case FETCH_CAMS_RESOURCES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_CAMS_RESOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                resources: action.payload,
            };
        case FETCH_CAMS_RESOURCES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case FETCH_GRADES_REQUEST_CAM:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_GRADES_SUCCESS_CAM:
            return {
                ...state,
                loading: false,
                camGrades: action.payload,
            };
        case FETCH_GRADES_FAILURE_CAM:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_CAMS_DETAILED_MARKS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_CAMS_DETAILED_MARKS_SUCCESS:
            return {
                ...state,
                loading: false,
                camViewResult: action.payload,
                error: ''
            };
        case FETCH_CAMS_DETAILED_MARKS_FAILURE:
            return {
                ...state,
                loading: false,
                camViewResult: [],
                error: action.payload
            };
        case FETCH_QUIZ_REPORT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_QUIZ_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                report: action.payload,
                error: ''
            };
        case FETCH_QUIZ_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                report: null,
                error: action.payload
            };
        case POST_QUIZ_REPORT_REQUEST_CAMS:
            return {
                ...state,
                loading: true,
            };
        case POST_QUIZ_REPORT_SUCCESS_CAMS:
            return {
                ...state,
                loading: false,
                quizReports: action.payload,
            };
        case POST_QUIZ_REPORT_FAILURE_CAMS:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case FETCH_STATUS_REQUEST_CAM:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_STATUS_SUCCESS_CAM:
            return {
                ...state,
                loading: false,
                status: action.payload,
                error: null
            };
        case FETCH_STATUS_FAILURE_CAM:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case FETCH_CAMS_SEND_TO_UMS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CAMS_SEND_TO_UMS_SUCCESS:
            return { ...state, loading: false, sendToCamsUMS: action.payload };
        case FETCH_CAMS_SEND_TO_UMS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case CAMS_ATTENDANCE_REQUEST:
            return { ...state, loading: true, error: null };
        case CAMS_ATTENDANCE_SUCCESS:
            return { ...state, loading: false, attendance: action.payload };
        case CAMS_ATTENDANCE_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case CAMS_UNLOCK_DATA_REQUEST:
            return { ...state, isFetching: true };
        case CAMS_UNLOCK_DATA_SUCCESS:
            return { ...state, isFetching: false, lock: action.payload };
        case CAMS_UNLOCK_DATA_FAILURE:
            return { ...state, isFetching: false, error: action.payload };

        case FETCH_CAMS_SEND_TO_UNS_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_CAMS_SEND_TO_UNS_SUCCESS:
            return { ...state, loading: false, sendToUni: action.payload };
        case FETCH_CAMS_SEND_TO_UNS_FAILURE:
            return { ...state, loading: false, error: action.payload };


        case RESET_STATE:
            return initialState;
        default:
            return state;
    }
};

export default universitiesReducer;
