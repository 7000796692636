// src/HomeSection.js
import React from 'react';
import Header from "./HeaderLayout";
import UnaHome from '../pages/UnaHome';
import Sidebar from './SideBar';
import "./style.scss";

function HomeSection() {
  return (
    <div className="home-section">
      <Header />
      <div className="container">
        <Sidebar />
        <main className="content">
          <UnaHome/>
        </main>
      </div>
    </div>
  );
}

export default HomeSection;
