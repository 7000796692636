import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Box, Typography, Button, CircularProgress, LinearProgress, IconButton } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import UploadImage from "../assets/upload-image.png";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileIcon from '../assets/excelsheet.png';
import { fetchTemplate, uploadTemplate } from '../action/universityActions';
import './common.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const FileUploadModal = ({ open, handleClose, selectLmsCourseId, selectedUniId, selectQuizId, selectAssessmentId, selectAssessmentOptionCheck, synctype }) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1); // 1: Upload, 2: Scanning, 3: Progress, 4: Success, 5: Error
    const [fileName, setFileName] = useState('');
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            setStep(1);
            setError('');
        }
    }, [open]);

    const handleFileChange = async (file) => {
        if (file) {
            if (isValidExcelFile(file)) {
                setFileName(file.name);
                setStep(2);

                try {
                    const result = await dispatch(uploadTemplate(file));
                    if (result.success) {
                        setStep(3);
                        let progressValue = 0;
                        const interval = setInterval(() => {
                            progressValue += 10;
                            setProgress(progressValue);
                            if (progressValue >= 100) {
                                clearInterval(interval);
                                setStep(4);
                            }
                        }, 500);
                    } else {
                        setError(result.message);
                        setStep(5);
                    }
                } catch (e) {
                    setError('An unexpected error occurred.');
                    setStep(5);
                }
            } else {
                alert('Please upload a valid Excel file (.xls or .xlsx).');
            }
        }
    };

    const isValidExcelFile = (file) => {
        const fileType = file.name.split('.').pop().toLowerCase();
        return fileType === 'xls' || fileType === 'xlsx';
    };

    const onDrop = useCallback((acceptedFiles) => {
        handleFileChange(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleDownloadTemplate = () => {
        dispatch(fetchTemplate(selectedUniId, selectLmsCourseId, selectQuizId, selectAssessmentId, selectAssessmentOptionCheck, synctype));
    };

    const renderContent = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Upload a file
                        </Typography>
                        <p className="download-Template-section" onClick={handleDownloadTemplate} style={{ cursor: 'pointer' }}>Click here to Download Template</p>
                        <div className="file-section" {...getRootProps()}>
                            <input {...getInputProps()} accept=".xls,.xlsx" />
                            <img src={UploadImage} width={60} height={60} alt="Upload" /><br /><br />
                            <b style={{ fontFamily: 'system-ui' }}>Drag and Drop your file here</b>
                            <p style={{ fontFamily: 'system-ui' }}>OR</p>
                            <b style={{ fontFamily: 'system-ui' }}>Browse file</b>
                        </div>
                        <Button
                            component="label"
                            className="upload-button"
                            style={{ marginTop: '100px' }}
                        >
                            Upload
                            <input
                                type="file"
                                hidden
                                onChange={e => handleFileChange(e.target.files[0])}
                                accept=".xls,.xlsx"
                            />
                        </Button>
                    </div>
                );
            case 2:
                return (
                    <div className="Scanning-modal">
                        <Typography id="modal-title" variant="h6" component="h2" style={{ marginBottom: "60px" }}>
                            Scanning your file...
                        </Typography>
                        <Typography variant="body1" component="p" style={{ marginTop: '20px', marginBottom: "60px", display: 'flex', alignItems: 'center' }}>
                            <img src={FileIcon} alt="File Icon" /> File: {fileName} <em>scanning</em>
                        </Typography>
                        <Box display="flex" justifyContent="center" alignItems="center" height="30%">
                            <CircularProgress />
                        </Box>
                        <button onClick={handleClose} className="close-button" style={{ marginLeft: "0px" }}>
                            Close
                        </button>
                    </div>
                );
            case 3:
                return (
                    <div className="uploading-modal">
                        <Typography id="modal-title" variant="h6" component="h2">
                            File Uploading
                        </Typography>
                        <Typography variant="body1" component="p" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                            <img src={FileIcon} alt="File Icon" /> File: {fileName} <em>uploading</em>
                        </Typography>
                        <LinearProgress variant="determinate" value={progress} style={{ marginTop: '20px' }} />
                        <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '10px' }}>
                            {progress}%
                        </Typography>
                        <button onClick={handleClose} className="Browser-button" style={{ marginRight: "40px", marginTop: "200px" }}>
                            Close
                        </button>
                    </div>
                );
            case 4:
                return (
                    <div className="file-upload">
                        <Typography id="modal-title" variant="h6" component="h2">
                            File uploaded successfully!
                        </Typography>
                        <div className="file-upload-section">
                            <Typography variant="body1" component="p" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                                <img src={FileIcon} alt="File Icon" /> File: {fileName} <em>uploaded</em>
                            </Typography>
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <CheckCircleIcon style={{ color: 'green', fontSize: 20 }} />
                            </Box>
                        </div>
                        <p>Quiz grades stored successfully...</p>
                        <button onClick={handleClose} className="uploaded-button" style={{ marginLeft: '0px' }}>
                            Close
                        </button>
                    </div>
                );
            case 5:
                return (
                    <div className="error-modal">
                        <Typography id="modal-title" variant="h6" component="h2" color="error">
                            Error
                        </Typography>
                        <Typography variant="body1" component="p" style={{ marginTop: '20px' }}>
                            {error}
                        </Typography>
                        <button onClick={handleClose} className="error-button" style={{ marginTop: '20px' }}>
                            Close
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={{ width: "500px", height: "700px", margin: "50px auto" }}
        >
            <Box sx={style}>
                <Button onClick={handleClose} className="button-close-modal">
                    <IconButton aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Button>
                {renderContent()}
            </Box>
        </Modal>
    );
};

export default FileUploadModal;
