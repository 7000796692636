// LockModal.jsx
import React from 'react';
import './LockModalStyle.scss';

const LockModal = ({ show, handleClose, handleConfirm }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay-lock">
            <div className="modal-lock">
                <h2>By clicking lock, you’ll be unable to re-sync and download above details. Do you really want to proceed?</h2>
                <button onClick={handleConfirm}>Yes</button>
                <button onClick={handleClose} className="no-button-lock">No</button>
            </div>
        </div>
    );
};

export default LockModal;
