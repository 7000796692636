import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCamsSendToUniversity, fetchCAMSAttendance, camsUnlockData, fetchCamsSendToUms, fetchStatusCAMS, postQuizReport, fetchUniversitiesCams, fetchCourseCategories, fetchCourses, fetchCamsResources, fetchGradesCams, fetchCamsDetailedMarks, fetchQuizReport } from '../action/CamsAction';
import SelectBox from '../commonComponent/SelectBox'; // Adjust path to your SelectBox component
import SyncModal from '../commonComponent/SyncModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LockModal from '../commonComponent/LockModal';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Pagination, MenuItem, Select, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import './style.scss';
import './Table.css';


const Cams = () => {
    const dispatch = useDispatch();
    const camState = useSelector(state => state.cams);
    const [universityCams, setUniversityCams] = useState('');
    const [universityCamsId, setUniversityCamsId] = useState(null);
    const [courseCategory, setCourseCategory] = useState('');
    const [courseCategoryId, setCourseCategoryId] = useState(null);
    const [course, setCourse] = useState('');
    const [courseId, setCourseId] = useState('');
    const [quiz, setQuiz] = useState('');
    const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
    const [step, setStep] = useState(1); // State to manage modal steps
    const [progress, setProgress] = useState(0); // State for progress bar
    const [synctype, setSyncType] = useState(0);
    const [isViewResult, setViewResult] = useState(false);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const [currentStudentPage, setCurrentStudentPage] = useState(1);
    const [rowsPerPageStudent, setRowsPerPageStudent] = useState(10);
    const [isLocked, setIsLocked] = useState(false);
    const [expanded, setExpanded] = useState("panel1");
    const [assessmentType, setAssessmentType] = useState("");
    const [isSyncClicked, setIsSyncClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [showTable, setShowTable] = useState(false);
    const notify = useSnackbar().enqueueSnackbar;
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const divRef = useRef(null);
    const roleName = localStorage.getItem('role')
    const userName = localStorage.getItem('username');
    const [validationErrors, setValidationErrors] = useState({
        university: '',
        courseCategory: '',
        course: '',
        quiz: '',
    });

    const getCurrentStudentData = () => {
        if (assessmentType === 'quiz') {
            return camState?.camViewResult?.quiz_report?.slice(
                (currentStudentPage - 1) * rowsPerPageStudent,
                currentStudentPage * rowsPerPageStudent
            );
        } else if (assessmentType === 'assignment') {
            return camState?.camViewResult?.assignment_report?.slice(
                (currentStudentPage - 1) * rowsPerPageStudent,
                currentStudentPage * rowsPerPageStudent
            );
        }
        return [];
    };

    const currentStudentData = getCurrentStudentData();
    const totalRecords = assessmentType === 'quiz'
        ? camState?.camViewResult?.quiz_report?.length || 0
        : camState?.camViewResult?.assignment_report?.length || 0;

    useEffect(() => {
        const startIndex = (currentStudentPage - 1) * rowsPerPageStudent;
        if (currentStudentPage > 1 && startIndex >= totalRecords) {
            setCurrentStudentPage(1);
        }
    }, [currentStudentPage, rowsPerPageStudent, currentStudentData, totalRecords]);

    useEffect(() => {
        if (camState?.camViewResult?.total_questions) {
            setMaxQuestions(camState.camViewResult.total_questions);
        }
    }, [camState]);


    useEffect(() => {
        if ((isViewResult) && divRef.current) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isViewResult]);


    useEffect(() => {
        dispatch(fetchUniversitiesCams());
    }, [dispatch]);



    const optionsUniversityCams = useMemo(() => camState.universitiesCAMS?.map(item => ({
        value: item.id,
        label: item.name
    })) || [], [camState.universitiesCAMS]);

    const optionCourseCategory = useMemo(() =>
        (Array.isArray(camState?.courseCategories) ? camState.courseCategories : []).map(item => ({
            value: item.id,
            label: item.name,
        })),
        [camState.courseCategories]
    );

    const optionCategory = useMemo(() => {
        if (Array.isArray(camState.courses?.courses)) {
            return camState.courses.courses.map(item => ({
                value: item.id,
                label: item.fullname,
            }));
        }
        return [];
    }, [camState?.courses?.courses]);


    const optionQuiz = useMemo(() => {
        if (Array.isArray(camState?.resources?.quizzes)) {
            return camState?.resources?.quizzes.map(item => ({
                value: item.id,
                label: item.name,
            }));
        }
        return [];
    }, [camState?.resources?.quizzes]);


    const optionsQuizSelect = (camState?.resources?.quizzes ?? []).map(
        (quiz) => ({
            value: quiz.id,
            label: quiz.name,
            source: "quiz",
        })
    );
    const optionsAssignment = (camState?.resources?.assignments ?? []).map(
        (assignment) => ({
            value: assignment.id,
            label: assignment.name,
            source: "assignment",
        })
    );

    const combinedOptions = [...optionsQuizSelect, ...optionsAssignment];

    const handleUniversityChangeCams = (event) => {
        const selectedUniValueCams = event.target.value;
        setUniversityCams(selectedUniValueCams);
        const selectedUniversityCams = optionsUniversityCams.find(option => option.value === selectedUniValueCams);

        if (selectedUniversityCams) {
            const universityId = selectedUniversityCams.value;
            setUniversityCamsId(universityId);
            dispatch(fetchCourseCategories(universityId));
            setCourseCategory('');
            setCourseCategoryId(null);
            setCourse('');
            setCourseId(null);
            setQuiz('');
        }
        setValidationErrors({
            ...validationErrors,
            university: '',
            courseCategory: '',
            course: '',
            quiz: '',
        });
        setViewResult(false);
    };

    const handleCourseCategoryChange = (event) => {
        const selectedCourseCategory = event.target.value;
        setCourseCategory(selectedCourseCategory);
        const selectedCourseCategoryOption = optionCourseCategory.find(option => option.value === selectedCourseCategory);

        if (selectedCourseCategoryOption) {
            const categoryId = selectedCourseCategoryOption.value;
            setCourseCategoryId(categoryId);
            if (universityCamsId) {
                dispatch(fetchCourses(universityCamsId, categoryId));
            }
            setCourse('');
            setCourseId(null);
            setQuiz('');
        }
        setValidationErrors({
            ...validationErrors,
            courseCategory: '',
            course: '',
            quiz: '',
        });
        setViewResult(false);

    };

    const handleCourseChange = (event) => {
        const selectedCourseValue = event.target.value;
        setCourse(selectedCourseValue);
        const selectedCourseOption = optionCategory.find(option => option.value === selectedCourseValue);
        if (selectedCourseOption) {
            const courseId = selectedCourseOption.value;
            setCourseId(courseId);

            if (universityCamsId) {
                dispatch(fetchCamsResources(universityCamsId, courseId));
            }
            setQuiz('');
        }
        setValidationErrors({
            ...validationErrors,
            course: '',
            quiz: '',
        });
        setViewResult(false);

    };

    const handleCloseSyncModal = () => {
        setIsSyncModalOpen(false);
    };

    const validateFields = () => {
        const errors = {
            university: '',
            courseCategory: '',
            course: '',
            quiz: '',
        };
        let hasErrors = false;

        if (!universityCamsId) {
            errors.university = 'Please select a university';
            hasErrors = true;
        }

        if (!courseCategoryId) {
            errors.courseCategory = 'Please select a Course Category';
            hasErrors = true;
        }

        if (!courseId) {
            errors.course = 'Please select a course';
            hasErrors = true;
        }

        if (!quiz) {
            errors.quiz = 'Please select a quiz';
            hasErrors = true;
        }

        return { hasErrors, errors };
    };

    const handleQuizChange = async (event) => {
        const selectedValue = event.target.value;
        setQuiz(selectedValue);
        const selectedOption = combinedOptions.find(
            (option) => option.value === parseInt(selectedValue, 10)
        );
        if (selectedOption) {
            setAssessmentType(selectedOption.source);
        }
        setValidationErrors({ ...validationErrors, quiz: "" });
        setViewResult(true);
        try {
            const status = await dispatch(fetchStatusCAMS(universityCamsId, courseId, selectedValue, 1));
            if (status === 'error') {
                setViewResult(false);
            } else {
                setViewResult(true);
            }
        } catch (error) {
            console.error("Error fetching status:", error);
            setViewResult(false);
        }
    };


    const handleSyncClick = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            return;
        }

        setIsSyncModalOpen(true);
        setIsSyncClicked(true);
        setProgress(0);
        setShowModal(false);

        const incrementProgress = () => {
            setProgress((prev) => {
                const nextProgress = prev + 10;
                if (nextProgress >= 100) {
                    clearInterval(timer);
                }
                return nextProgress;
            });
        };

        const timer = setInterval(incrementProgress, 300);

        const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        try {
            let statusResponse = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));

            if (statusResponse === "error") {
                await dispatch(fetchGradesCams(universityCamsId, courseId, quiz, 0, assessmentType));
                // Call fetchStatusCAMS again after fetching grades
                statusResponse = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));
                console.log(statusResponse, "statusResponse after fetchGradesCams");
            }

            if (statusResponse === "success" || statusResponse?.SyncGradeLock?.lock === 1) {
                // Call fetchCamsDetailedMarks if status is success or lock is 1
                await dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType));
                setShowTable(true); // Ensure table is shown
                setViewResult(true)
            } else if (statusResponse?.SyncGradeLock?.lock === null) {
                // If lock is null, set step to 2, show the modal, then call fetchCamsDetailedMarks
                setStep(2);
                await waitFor(2000);
                await dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType));
                setShowTable(true); // Ensure table is shown
                setViewResult(true)
            }
            setIsSyncModalOpen(false);
        } catch (error) {
            console.log(error, "fetchStatusCAMS error");
            try {
                await dispatch(fetchGradesCams(universityCamsId, courseId, quiz, 0, assessmentType));
                // Call fetchStatusCAMS again after fetching grades
                const statusResponseAfterError = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));
                console.log(statusResponseAfterError, "statusResponse after fetchGradesCams in catch block");

                if (statusResponseAfterError === "success") {
                    await dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType));
                    setShowTable(true); // Ensure table is shown
                }
            } catch (fetchGradesError) {
                console.error(fetchGradesError, "fetchGradesCams error");
                setStep(3);
                await waitFor(2000);
                setStatusMessage(fetchGradesError.message);
            } finally {
                setShowTable(true);
                setIsSyncModalOpen(false);
            }
        } finally {
            clearInterval(timer);
            setIsSyncClicked(false);
        }
    };

    const handleViewResult = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            try {
                const { status, data } = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));
                if (status === 404 || data === 'Status not found') {
                    setViewResult(false);
                    setIsLoading(false);
                    return;
                }
                await dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType));
                setIsLoading(false);
                setViewResult(true); // Show the table after successful fetch
                setShowTable(true);
                setExpanded(false);
            } catch (error) {
                console.error("Error handling confirm lock:", error);
                setIsLoading(false);
            }
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPageStudent(event.target.value);
        setCurrentStudentPage(1); // Reset to the first page
    };

    // Handle page change
    const handlePageChange = (event, value) => {
        setCurrentStudentPage(value);
    };


    const headers = ["Sr No", "Student Name", "Enrollment Number"];
    for (let i = 1; i <= maxQuestions; i++) {
        headers.push(`Q${i}`);
    }
    headers.push("Total", "Attendance", "Status");


    const handleDownloadXl = () => {
        dispatch(
            fetchQuizReport(
                universityCamsId,
                courseId,
                quiz,
                assessmentType
            )
        );
    };


    const handleReSync = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
        } else {
            setIsLoading(true); // Start loading
            try {
                await Promise.all([
                    dispatch(fetchGradesCams(universityCamsId, courseId, quiz, 0, assessmentType)),
                    dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType))
                ]);
            } catch (error) {
                console.error("Error fetching manual template:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSendToUMS = () => {
        dispatch(fetchCamsSendToUms(universityCamsId, courseCategoryId, courseId, quiz, assessmentType, userName, notify))
    }

    const handleSendToUNI = () => {
        dispatch(fetchCamsSendToUniversity(universityCamsId, courseCategoryId, courseId, quiz, assessmentType, userName, notify))
    }

    const handleSendToUniversityClick = () => {
        // Dispatch the fetchCAMSAttendance action
        dispatch(fetchCAMSAttendance(universityCamsId, courseId, quiz, assessmentType, userName, notify));
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleLock = async () => {
        if (camState?.status?.SyncGradeLock?.lock === 1) {
            // If the lock is in the "Unlock" state, show the modal
            setShowModal(true);
        } else {
            // If the lock is in the "Lock" state, call the postQuizReport API
            await handlePostQuizReport();
        }
    };

    const handlePostQuizReport = async () => {
        try {
            await dispatch(postQuizReport(universityCamsId, courseId, quiz, assessmentType, userName, notify));
            // Re-check the SyncGradeLock status after postQuizReport
            let statusResponse = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));
            console.log(statusResponse, "statusResponse")
        } catch (error) {
            console.error("Error handling post quiz report:", error);
        }
    };


    const handleConfirmLock = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            setIsLoading(false);
            return; // Stop execution if there are validation errors
        }
        setIsLoading(true);
        try {
            // Call camsUnlockData API
            await dispatch(camsUnlockData(universityCamsId, courseId, quiz, assessmentType, userName));

            // Wait for 1 second to allow the SyncGradeLock status to update
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Re-check the SyncGradeLock status after camsUnlockData
            let statusResponse = await dispatch(fetchStatusCAMS(universityCamsId, courseId, quiz, 1));
            console.log(statusResponse, "statusResponse");

            // Check if the SyncGradeLock status has been updated to null
            if (statusResponse?.SyncGradeLock?.lock === null) {
                // Close modal only after camsUnlockData is completed
                setShowModal(false);

                // Fetch detailed marks and update UI
                await dispatch(fetchCamsDetailedMarks(universityCamsId, courseId, quiz, assessmentType));
                setShowTable(true); // Show the table
            } else {
                console.log("SyncGradeLock status not updated to null");
            }
        } catch (error) {
            console.error("Error handling confirm lock:", error);
        } finally {
            setIsLoading(false); // Ensure loading state is reset in any case
        }
    };



    return (
        <div style={{ display: 'block' }}>
            <Box className="cams-wrapper" style={{ width: '87vw' }}>
                <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleAccordionChange("panel1")}
                    style={{ width: '100vw' }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Select CAMS Details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className="select-box-wrapper">
                            <SelectBox
                                label="Select a CAMS"
                                options={optionsUniversityCams}
                                value={universityCams}
                                onChange={handleUniversityChangeCams}
                                className="select-section"
                                error={validationErrors.university}
                            />
                            <SelectBox
                                label="Select a Course Category"
                                options={optionCourseCategory}
                                value={courseCategory}
                                onChange={handleCourseCategoryChange}
                                className="select-section"
                                error={validationErrors.courseCategory}
                            />
                            <SelectBox
                                label="Select a Course"
                                options={optionCategory}
                                value={course}
                                onChange={handleCourseChange}
                                className="select-section"
                                error={validationErrors.course}
                            />
                            <SelectBox
                                label="Select a Quiz"
                                options={optionQuiz}
                                value={quiz}
                                onChange={handleQuizChange}
                                className="select-section"
                                error={validationErrors.quiz}
                            />

                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <div>
                <div style={{ gap: '50px', display: 'flex' }}>
                    <button
                        className={`Browser-button-sync ${isLocked ? "disabled" : ""}`}
                        disabled={camState?.status?.SyncGradeLock?.lock === 1}
                        onClick={handleSyncClick}
                    >
                        Sync
                    </button>
                    <button className="Browser-button-view" onClick={handleViewResult} disabled={!isViewResult}>
                        View Result
                    </button>
                </div>

                <SyncModal isOpen={isSyncModalOpen} onClose={handleCloseSyncModal} step={step} progress={progress} statusMessage={statusMessage} />
            </div>

            {(showTable) && (
                <div className="scrollable-div">
                    {camState.loading || isLoading ? (
                        <div className="loader">
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <h3>View Student Result</h3>
                            <p className="stats-container">
                                {[
                                    // universityCamsId && optionsUniversityCams.find((option) => option.value === universityCamsId)?.label && `CAMS - ${optionsUniversityCams.find((option) => option.value === universityCamsId)?.label}`,
                                    // courseCategoryId && optionCourseCategory.find((option) => option.value === courseCategoryId)?.label && `Course Category - ${optionCourseCategory.find((option) => option.value === courseCategoryId)?.label}`,
                                    // courseId && optionCategory.find((option) => option.value === courseId)?.label && `Course - ${optionCategory.find((option) => option.value === courseId)?.label}`,
                                    quiz && combinedOptions.find((option) => option.value === parseInt(quiz, 10))?.label && `Quiz - ${combinedOptions.find((option) => option.value === parseInt(quiz, 10))?.label}`
                                ]
                                    .filter(Boolean)
                                    .map((text, index, array) => (
                                        <span key={index}>
                                            {text}
                                            {index < array.length - 1 && ', '}
                                        </span>
                                    ))}
                            </p>

                            <p className="stats-container">
                                {camState?.camViewResult?.total_records ? <span> Total Student - {camState?.camViewResult?.total_records}</span> : " "}
                                {camState?.camViewResult?.total_absent_records ? <span> Total Absent - {camState?.camViewResult?.total_absent_records}</span> : " "}
                                {camState?.camViewResult?.total_present_records ? <span> Total Present - {camState?.camViewResult?.total_present_records}</span> : " "}
                                {camState?.camViewResult?.total_grading_complete ? <span> Total Grading Complete - {camState?.camViewResult?.total_grading_complete}</span> : " "}
                                {camState?.camViewResult?.total_grading_incomplete !== null && camState?.camViewResult?.total_grading_incomplete !== undefined ? (
                                    <span>Total Grading Incomplete - {camState?.camViewResult?.total_grading_incomplete}</span>) : (" ")
                                }
                                {camState?.camViewResult?.submitted_count ? <span> Total Submitted Count - {camState?.camViewResult?.submitted_count}</span> : " "}
                                {camState?.camViewResult?.not_submitted_count ? <span> Total Not Submitted Count - {camState?.camViewResult?.not_submitted_count}</span> : " "}
                                {camState?.camViewResult?.submitted_grading_count ? <span> Total Submitted Grading Count - {camState?.camViewResult?.submitted_grading_count}</span> : " "}
                            </p>
                            <div className="table-container">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            {/* Fixed headers */}
                                            <th className="fixed fixed-col-header sr-no">Sr No</th>
                                            <th className="fixed fixed-col-header student-name">Student Name</th>
                                            <th className="fixed fixed-col-header enrollment-number">Enrollment Number</th>
                                            {/* Scrollable headers */}
                                            {Array.from({ length: maxQuestions }).map((_, i) => (
                                                <th key={`Q${i + 1}`} className="scrollable">Q{i + 1}</th>
                                            ))}
                                            {/* Fixed footer columns */}
                                            <th className="fixed">Total</th>
                                            <th className="fixed">Attendance</th>
                                            <th className="fixed">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentStudentData?.length > 0 ? (
                                            currentStudentData.map((item, index) => (
                                                <tr key={item.student_id}>
                                                    {/* Fixed columns */}
                                                    <td className="fixed fixed-col sr-no">{(currentStudentPage - 1) * rowsPerPageStudent + index + 1}</td>
                                                    <td className="fixed fixed-col student-name">{item.student_name}</td>
                                                    <td className="fixed fixed-col enrollment-number">{item.enrollment_number || '-'}</td>
                                                    {/* Scrollable cells */}
                                                    {Array.isArray(item.individual_question_marks) && item.individual_question_marks.length > 0
                                                        ? item.individual_question_marks.map((mark, markIndex) => (
                                                            <td
                                                                key={markIndex}
                                                                className="scrollable"
                                                                style={{
                                                                    backgroundColor:
                                                                        mark.question_mark === null
                                                                            ? 'white' // Blank
                                                                            : mark.question_mark === ""
                                                                                ? 'red' // Red colored cell (Grading Incomplete)
                                                                                : item.attendance_status === "Absent"
                                                                                    ? 'lightgray' // Hyphen (-) if attendance_status is Absent
                                                                                    : 'lightgray', // default color
                                                                }}
                                                            >
                                                                {mark.question_mark === null
                                                                    ? "" // Blank
                                                                    : mark.question_mark === ""
                                                                        ? "" // Red colored cell (Grading Incomplete)
                                                                        : item.attendance_status === "Absent"
                                                                            ? "-" // Hyphen (-) if attendance_status is Absent
                                                                            : mark.question_mark}
                                                            </td>
                                                        ))
                                                        : Array.from({ length: maxQuestions }).map((_, i) => (
                                                            <td key={`empty-${i}`} className="scrollable empty-cell" style={{ backgroundColor: 'white', fontSize: '20px', textAlign: 'center' }}>
                                                                -
                                                            </td>
                                                        ))}
                                                    {/* Fixed footer cells */}
                                                    <td className="fixed">{item.total_marks || '-'}</td>
                                                    <td className="fixed">{item.attendance_status}</td>
                                                    <td className="fixed" style={{ textTransform: 'capitalize' }}>{item.status || 'N/A'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                                                    No records found for the current page.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot className="fixed-footer">
                                        <tr>
                                            <td colSpan={headers.length} style={{ textAlign: 'left' }}><br />
                                                <strong>Legend:</strong>
                                                <ul className="legends-lists">
                                                    <li>Hyphen (-) = Absent</li><br />
                                                    <li>Blank = Not Attempted</li><br />
                                                    <li><span style={{ backgroundColor: 'red', padding: "1px 20px", marginRight: '5px' }}></span> = Grading Incomplete</li><br />
                                                </ul>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div><br />

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                }}
                            >
                                <label>
                                    Records per page:
                                    <Select
                                        value={rowsPerPageStudent}
                                        onChange={handleRowsPerPageChange}
                                        style={{
                                            marginLeft: "10px",
                                            marginTop: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </label>
                            </div>

                            <div className="button-toggle-wrapper">
                                {roleName === "admin" && (
                                    <>
                                        {camState.status.SyncGradeLock.lock === null ?
                                            <button className={`lock-button`} onClick={handleLock} > Lock </button> :
                                            <button className={`lock-button`} onClick={handleConfirmLock}> Unlock</button>
                                        }
                                    </>
                                )}
                                <button
                                    className={`send-to-ums-button ${isLocked ? "disabled" : ""}`}
                                    disabled={camState.status.SyncGradeLock.lock === null}
                                    onClick={handleSendToUMS}
                                >
                                    Send to UMS
                                </button>
                                <button
                                    className={`send-to-ums-button ${isLocked ? "disabled" : ""}`}
                                    disabled={camState.status.SyncGradeLock.lock === null}
                                    onClick={handleSendToUNI}
                                >
                                    Send to University
                                </button>
                                <button
                                    className={`re-sync-button ${isLocked ? "disabled" : ""}`}
                                    disabled={camState.status.SyncGradeLock.lock === 1}
                                    onClick={handleReSync}
                                >
                                    Re-Sync
                                </button>
                                <button
                                    className="download-button"
                                    onClick={handleDownloadXl}
                                >
                                    Download
                                </button>
                                <button
                                    className="download-button"
                                    onClick={handleSendToUniversityClick}
                                >
                                    Send Attendance
                                </button>
                            </div>

                            <LockModal
                                show={showModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirmLock}
                            />
                            <div
                                className="pagination"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                }}
                            >
                                <Pagination
                                    count={Math.ceil(totalRecords / rowsPerPageStudent)}
                                    page={currentStudentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                    shape="rounded"
                                    siblingCount={1}
                                    boundaryCount={1}
                                    showFirstButton
                                    showLastButton
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>

    );
};

export default Cams;
