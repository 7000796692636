import React from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

const Sidebar = () => {
  return (
    <nav className="sidebar">
      <ul>
        <div className="mapone">
          <li><Link to="/">Sync Marks</Link></li>
        </div>
      </ul>
    </nav>
  );
};

export default Sidebar;
