import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { loginAndGenerateToken, fetchUniversities } from '../action/universityActions';
import './SignIn.scss'; // Adjust the path as necessary

const SignIn = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedInUsername, setLoggedInUsername] = useState('');
  const [errorState, setErrorState] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');

    if (storedUsername && storedPassword) {
      setLoggedInUsername(storedUsername); // Set logged-in username state
      setIsLoading(true);
      dispatch(loginAndGenerateToken(storedUsername, storedPassword))
        .then(finalToken => {
          dispatch(fetchUniversities(finalToken));
          navigate('/dashboard');
          window.location.reload();
        })
        .catch(error => {
          console.error('Auto-login failed:', error);
          localStorage.removeItem('username');
          localStorage.removeItem('password');
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorState('');

    try {
      if (!username || !password) {
        setErrorState('Username and Password are required.');
        return;
      }
      setIsLoading(true);
      const finalToken = await dispatch(loginAndGenerateToken(username, password));
      dispatch(fetchUniversities(finalToken));
      localStorage.setItem('username', username); // Save username to local storage
      setLoggedInUsername(username); // Set logged-in username state
      navigate('/dashboard');
      window.location.reload();
    } catch (error) {
      console.error('Error:', error);
      setErrorState('Invalid username or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = username && password;

  return (
    <div className="signin-container">
      <h1>Grading Evaluation Management System (GEMS)</h1>
      {errorState && <p className="error">{errorState}</p>}
      {isLoading ? (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          <p>Loading...</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username" style={{ marginRight: '300px' }}>
              Username *
            </label>
            <input
              type="text"
              id="username"
              value={username}
              placeholder="Enter Username"
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password" style={{ marginRight: '300px' }}>
              Password *
            </label>
            <input
              type="password"
              id="password"
              value={password}
              placeholder="Enter Password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {/* <div className="forgot-password">
            <a href="/">Forgot Password?</a>
          </div> */}
          <button
            type="submit"
            disabled={isLoading}
            className={`signInButton ${isFormValid ? 'filled' : 'empty'}`}
          >
            Sign In
          </button>
        </form>
      )}
      {loggedInUsername && <p>Welcome, {loggedInUsername}!</p>}
    </div>
  );
};

export default SignIn;
