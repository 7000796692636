import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchSendToUms,
    fetchStatus,
    postQuizReport,
    fetchDownloadTemplate,
    fetchMarks,
    fetchResources,
    fetchSubjects,
    fetchAssessment,
    fetchManualTemplate,
    fetchUniversities,
    fetchCourses,
    loginAndGenerateToken,
    fetchBatches,
    fetchSemester,
    fetchExam,
    fetchGrades,
    resetState,
    fetchSendToUNI,
    fetchSendToAttendance,
    unlockData
} from "../action/universityActions";
import { fetchUniversitiesCams } from "../action/CamsAction";
import SelectBox from "../commonComponent/SelectBox";
import FileUploadModal from "../commonComponent/Modal";
import SyncModal from "../commonComponent/SyncModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import GroupSelectBox from "../commonComponent/GroupSelectBox";
import Cams from "./Cams";
import LockModal from "../commonComponent/LockModal";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Pagination,
    MenuItem,
    Select,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSnackbar } from "notistack";
import "./style.scss";
import axios from "axios";

const UnaHome = () => {
    const universitiesState = useSelector((state) => state.universities);
    const [selectedUniversity, setSelectedUniversity] = useState("");
    const [selectedUniId, setSelectedUniId] = useState(null);
    const [selectBatchId, setSelectBatchId] = useState(null);
    const [selectCourseId, setSelectCourseId] = useState(null);
    const [selectSemesterId, setSelectSemesterId] = useState(null);
    const [selectExamId, setSelectExamId] = useState(null);
    const [selectAssessment, setSelectAssessment] = useState("");
    const [selectAssessmentId, setSelectAssessmentId] = useState(null);
    const [selectAssessmentOptionCheck, setSelectAssessmentOptionCheck] = useState("");
    const [selectQuizId, setSelectQuizId] = useState(null);
    const [selectExamSubId, setSelectExamSubId] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState("");
    const [selectSem, setSelectSem] = useState("");
    const [selectBatch, setSelectBatch] = useState("");
    const [selectExamSub, setSelectExamSub] = useState("");
    const [selectExam, setSelectExam] = useState("");
    const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
    const [isViewResult, setViewResult] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const [isManualEntryOpen, setIsManualEntryOpen] = useState(false);
    const [isOnline, setIsOnline] = useState(true);
    const [selectionType, setSelectionType] = useState("0");
    const [synctype, setSyncType] = useState(0);
    const [isOffline, setIsOffline] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10); // or any other number of items per page
    const [selectLmsCourseId, setLmsCourseId] = useState(null);
    const [displayData, setDisplayData] = useState(universitiesState);
    const [step, setStep] = useState(1); // State to manage modal steps
    const [progress, setProgress] = useState(0); // State for progress bar
    const [finalToken, setFinalToken] = useState(null);
    const [universitiesFetched, setUniversitiesFetched] = useState(false);
    const [maxQuestions, setMaxQuestions] = useState(0);
    const [currentStudentPage, setCurrentStudentPage] = useState(1);
    const [rowsPerPageStudent, setRowsPerPageStudent] = useState(10);
    const [showButtons, setShowButtons] = useState(true);
    const [isLocked, setIsLocked] = useState(false);
    const [isSyncClicked, setIsSyncClicked] = useState(false);
    const [expanded, setExpanded] = useState("panel1");
    const [assessmentType, setAssessmentType] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [optionsQuiz, setOptionsQuiz] = useState([]);
    const [statusMessage, setStatusMessage] = useState("");
    const [selectSubjectCode, setSelectSubjectCode] = useState(null);
    const roleName = localStorage.getItem("role");
    const userName = localStorage.getItem('username');
    const notify = useSnackbar().enqueueSnackbar;
    const dispatch = useDispatch();
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        if (universitiesState?.quizzes) {
            setOptionsQuiz(universitiesState.quizzes);
        }
    }, [universitiesState?.quizzes]);

    const getCurrentStudentData = () => {
        if (assessmentType === "quiz") {
            return universitiesState?.marks?.quiz_report?.slice(
                (currentStudentPage - 1) * rowsPerPageStudent,
                currentStudentPage * rowsPerPageStudent
            );
        } else if (assessmentType === "assignment") {
            return universitiesState?.marks?.assignment_report?.slice(
                (currentStudentPage - 1) * rowsPerPageStudent,
                currentStudentPage * rowsPerPageStudent
            );
        }
        return [];
    };

    const currentStudentData = getCurrentStudentData();
    const totalRecords =
        assessmentType === "quiz"
            ? universitiesState?.marks?.quiz_report?.length || 0
            : universitiesState?.marks?.assignment_report?.length || 0;

    useEffect(() => {
        const startIndex = (currentStudentPage - 1) * rowsPerPageStudent;
        if (currentStudentPage > 1 && startIndex >= totalRecords) {
            setCurrentStudentPage(1);
        }
    }, [
        currentStudentPage,
        rowsPerPageStudent,
        currentStudentData,
        totalRecords,
    ]);

    useEffect(() => {
        if (universitiesState?.marks?.total_questions) {
            setMaxQuestions(universitiesState.marks.total_questions);
        }
    }, [universitiesState]);

    useEffect(() => {
        dispatch(resetState());
        setViewResult(false);
        setShowTable(false);
        setCurrentStudentPage(1);
    }, [selectionType, dispatch]);

    useEffect(() => {
        const storedUsername = localStorage.getItem("username");
        const storedPassword = localStorage.getItem("password");

        if (storedUsername && storedPassword) {
            dispatch(loginAndGenerateToken(storedUsername, storedPassword));
        }
    }, [dispatch]);

    const memoizedDependencies = useMemo(
        () => ({
            username: universitiesState.username,
            password: universitiesState.password,
            selectedUniId,
            selectBatchId,
            selectCourseId,
            selectSemesterId,
            selectExamId,
            selectExamSubId,
            selectAssessmentId,
            selectAssessmentOptionCheck,
            selectQuizId,
        }),
        [
            universitiesState.username,
            universitiesState.password,
            selectedUniId,
            selectBatchId,
            selectCourseId,
            selectSemesterId,
            selectExamId,
            selectExamSubId,
            selectAssessmentId,
            selectAssessmentOptionCheck,
            selectQuizId,
        ]
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!finalToken) {
                    const token = await dispatch(
                        loginAndGenerateToken(
                            memoizedDependencies.username,
                            memoizedDependencies.password
                        )
                    );
                    setFinalToken(token); // Store the token in state
                }
                if (finalToken && !universitiesFetched) {
                    await dispatch(fetchUniversities());
                    setUniversitiesFetched(true);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [
        dispatch,
        finalToken,
        memoizedDependencies.username,
        memoizedDependencies.password,
    ]);

    useEffect(() => {
        if ((isManualEntryOpen || isViewResult) && divRef.current) {
            divRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isManualEntryOpen, isViewResult]);

    // Handle page change
    const handlePageChange = (event, value) => {
        setCurrentStudentPage(value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPageStudent(event.target.value);
        setCurrentStudentPage(1); // Reset to the first page
    };

    const headers = ["Sr No", "Student Name", "Enrollment Number"];
    for (let i = 1; i <= maxQuestions; i++) {
        headers.push(`Q${i}`);
    }
    const showAttendance = universitiesState?.marks?.assignment_report?.some(
        (report) => report.attendance_status === undefined
    );
    if (showAttendance) {
        headers.push("Total", "Status");
    } else {
        headers.push("Total", "Attendance", "Status");
    }

    const divRef = useRef(null);
    const [validationErrors, setValidationErrors] = useState({
        university: "",
        batch: "",
        course: "",
        semester: "",
        exam: "",
        topic: "",
        examSubject: "",
        selectCam: "",
        quiz: "",
        assessment: "",
    });

    const rowsPerPage = 5;
    const optionsUniversity = useMemo(
        () =>
            universitiesState?.universities?.map((item) => ({
                value: item.unvId,
                label: item.univeristyName,
            })) || [],
        [universitiesState?.universities]
    );

    const optionsBatches = useMemo(
        () =>
            universitiesState?.batches?.map((item) => ({
                value: item.batchId,
                label: item.batchName,
            })) || [],
        [universitiesState?.batches]
    );

    const optionsCourses = useMemo(
        () =>
            universitiesState?.courses?.map((item) => ({
                value: item.courseId,
                label: item.courseName,
            })) || [],
        [universitiesState?.courses]
    );

    const optionsSemester = useMemo(
        () =>
            universitiesState?.semester?.map((item) => ({
                value: item.semesterId,
                label: item.semesterName,
            })) || [],
        [universitiesState?.semester]
    );

    const optionsExam = useMemo(
        () =>
            universitiesState?.exam?.map((item) => ({
                value: item.examId,
                label: item.examName,
            })) || []
    );

    const optionsExamSubject = useMemo(
        () =>
            universitiesState?.subject?.map((item) => ({
                value: item.lmsCourseId,
                label: item.subjectName,
                subjectCode: item.subjectCode
            })) || [],
        [universitiesState?.subject]
    );

    const optionsAssessment = useMemo(() => {
        return (
            universitiesState?.assessment?.map((item) => ({
                value: item.assessmentId,
                label: item.assessmentName,
            })) || []
        );
    }, [universitiesState?.assessment]);

    const optionsQuizSelect = (universitiesState?.quizzes?.quizzes ?? []).map(
        (quiz) => ({
            value: quiz.id,
            label: quiz.name,
            source: "quiz",
        })
    );

    const optionsAssignment = (universitiesState?.quizzes?.assignments ?? []).map(
        (assignment) => ({
            value: assignment.id,
            label: assignment.name,
            source: "assignment",
        })
    );

    const combinedOptions = [...optionsQuizSelect, ...optionsAssignment];

    const handleUniversityChange = (event) => {
        const selectedUniValue = event.target.value;
        setSelectedUniversity(selectedUniValue);
        const selectedUniversity = optionsUniversity.find(
            (option) => option.value === selectedUniValue
        );

        if (selectedUniversity) {
            setSelectedUniId(selectedUniversity.value);
            dispatch(fetchBatches(selectedUniversity.value)).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectedCourse("");
            setSelectCourseId(null);
            setSelectSem("");
            setSelectSemesterId(null);
            setSelectExam("");
            setSelectExamId(null);
            setSelectExamSub("");
            setSelectExamSubId(null);
            setSelectQuizId(null);
            setSelectAssessment("");
            setSelectAssessmentId(null);
            setValidationErrors({
                ...validationErrors,
                university: "",
                batch: "",
                course: "",
                semester: "",
                exam: "",
                topic: "",
                examSubject: "",
                selectCam: "",
                quiz: "",
                assessment: "",
            });
        }
        setViewResult(false);
    };

    const handleBatchChange = (event) => {
        const selectedBatchValue = event.target.value;
        setSelectBatch(selectedBatchValue);
        const selectBatch = optionsBatches.find(
            (option) => option.value === selectedBatchValue
        );

        if (selectBatch) {
            setSelectBatchId(selectBatch.value);
            dispatch(fetchCourses(selectBatch.value)).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectedCourse("");
            setSelectCourseId(null);
            setSelectSem("");
            setSelectSemesterId(null);
            setSelectExam("");
            setSelectExamId(null);
            setSelectExamSub("");
            setSelectExamSubId(null);
            setSelectQuizId(null);
            setSelectAssessment("");
            setSelectAssessmentId(null);
        }

        setValidationErrors({
            ...validationErrors,
            batch: "",
            course: "",
            semester: "",
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleCourseChange = (event) => {
        const selectedCourseValue = event.target.value;
        setSelectedCourse(selectedCourseValue);
        const selectedCourse = optionsCourses.find(
            (option) => option.value === selectedCourseValue
        );

        if (selectedCourse) {
            setSelectCourseId(selectedCourse.value);
            dispatch(fetchSemester(selectedCourse.value)).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectSem("");
            setSelectSemesterId(null);
            setSelectExam("");
            setSelectExamId(null);
            setSelectExamSub("");
            setSelectExamSubId(null);
            setSelectQuizId(null);
            setSelectAssessment("");
            setSelectAssessmentId(null);
        }

        setValidationErrors({
            ...validationErrors,
            course: "",
            semester: "",
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleSemChange = (event) => {
        const selectedSemValue = event.target.value;
        setSelectSem(selectedSemValue);
        const selectedSemester = optionsSemester.find(
            (option) => option.value === selectedSemValue
        );

        if (selectedSemester) {
            setSelectSemesterId(selectedSemester.value);
            dispatch(fetchExam(selectedSemester.value)).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectExam("");
            setSelectExamId(null);
            setSelectExamSub("");
            setSelectExamSubId(null);
            setSelectQuizId(null);
            setSelectAssessment("");
            setSelectAssessmentId(null);
        }
        setValidationErrors({
            ...validationErrors,
            semester: "",
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleExanChange = (event) => {
        const selectedExamValue = event.target.value;
        setSelectExam(selectedExamValue);
        const selectedExam = optionsExam.find(
            (option) => option.value === selectedExamValue
        );

        if (selectedExam) {
            setSelectExamId(selectedExam.value);
            dispatch(fetchAssessment(selectedExam.value)).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectExamSub("");
            setSelectExamSubId(null);
            setSelectQuizId(null);
            setSelectAssessment("");
            setSelectAssessmentId(null);
        }

        // Reset validation errors
        setValidationErrors({
            ...validationErrors,
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleAssessmentType = (event) => {
        const selectedAssessment = event.target.value;
        setSelectAssessment(selectedAssessment);
        const selectedAssessmentOption = optionsAssessment.find(
            (option) => option.value === selectedAssessment
        );
        if (selectedAssessmentOption) {
            setSelectAssessmentId(selectedAssessmentOption.value);
            setSelectAssessmentOptionCheck(selectedAssessmentOption.label);
            dispatch(
                fetchSubjects(selectExamId, selectedAssessmentOption.value)
            ).then((response) => {
                setOptionsQuiz(response?.quizzes);
            });
            setSelectExamSub("");
            setSelectExamSubId(null);
        }

        // Reset validation errors
        setValidationErrors({
            ...validationErrors,
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleExamSubChange = (event) => {
        const selectedExamSubValue = parseInt(event.target.value, 10);
        setSelectExamSub(selectedExamSubValue); // Update the state with the selected value
        const selectedExamSub = optionsExamSubject.find(
            (option) => option.value == selectedExamSubValue
        );

        if (selectedExamSub) {
            setSelectExamSubId(selectedExamSub.value);
            setLmsCourseId(selectedExamSub.value);
            const { subjectCode } = selectedExamSub;
            setSelectSubjectCode(subjectCode);
            dispatch(fetchResources(selectedUniId, selectedExamSub.value));
        }
        setValidationErrors({
            ...validationErrors,
            examSubject: "",
            topic: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        });
        setViewResult(false);
    };

    const handleQuizChange = async (event) => {
        const selectedValue = event.target.value;
        setSelectQuizId(selectedValue);
        const selectedOption = combinedOptions.find(
            (option) => option.value === parseInt(selectedValue, 10)
        );
        if (selectedOption) {
            setAssessmentType(selectedOption.source);
        }
        setValidationErrors({ ...validationErrors, quiz: "" });
        try {
            const status = await dispatch(
                fetchStatus(selectedUniId, selectLmsCourseId, selectedValue, 1)
            );
            if (status === "error") {
                setViewResult(false);
            } else {
                setViewResult(true);
            }
        } catch (error) {
            console.error("Error fetching status:", error);
            setViewResult(false);
        }
    };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        if (value === "0") {
            // '0' for online
            setIsOnline(true);
            setIsOffline(false);
            setSyncType(0);
            setIsManualEntryOpen(false);
        } else {
            // '1' for offline
            setIsOnline(false);
            setIsOffline(true);
            setSyncType(1);
            setIsManualEntryOpen(false);
        }
    };

    const validateFields = () => {
        const errors = {
            university: "",
            batch: "",
            course: "",
            semester: "",
            exam: "",
            topic: "",
            examSubject: "",
            selectCam: "",
            quiz: "",
            assessment: "",
        };
        let hasErrors = false;

        if (!selectedUniId) {
            errors.university = "Please select a university";
            hasErrors = true;
        }

        if (!selectBatchId) {
            errors.batch = "Please select a batch";
            hasErrors = true;
        }

        if (!selectCourseId) {
            errors.course = "Please select a course";
            hasErrors = true;
        }

        if (!selectSemesterId) {
            errors.semester = "Please select a semester";
            hasErrors = true;
        }

        if (!selectExamId) {
            errors.exam = "Please select an exam";
            hasErrors = true;
        }

        if (!selectQuizId) {
            errors.quiz = "Please select a Quiz";
            hasErrors = true;
        }

        if (!selectAssessment) {
            errors.assessment = "Please select a Assessment";
            hasErrors = true;
        }

        if (!selectExamSubId) {
            errors.examSubject = "Please select an exam subject";
            hasErrors = true;
        }

        return { hasErrors, errors };
    };

    const handleSyncClick = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            return;
        }

        setIsSyncModalOpen(true);
        setIsSyncClicked(true);
        setProgress(0);
        setShowModal(false);

        const incrementProgress = () => {
            setProgress((prev) => {
                const nextProgress = prev + 10;
                if (nextProgress >= 100) {
                    clearInterval(timer);
                }
                return nextProgress;
            });
        };

        const timer = setInterval(incrementProgress, 300);

        const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

        try {
            let statusResponse = await dispatch(
                fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1)
            );
            console.log(statusResponse, "statusResponse")

            if (statusResponse === "error") {
                await dispatch(
                    fetchGrades(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType,
                        synctype
                    )
                );
                // Call fetchStatusCAMS again after fetching grades
                statusResponse = await dispatch(
                    fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1)
                );
                console.log(statusResponse, "statusResponse after fetchGradesCams");
            }

            if (
                statusResponse === "success" ||
                statusResponse?.SyncGradeLock?.lock === 1
            ) {
                // Call fetchCamsDetailedMarks if status is success or lock is 1
                await dispatch(
                    fetchMarks(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType
                    )
                );
                setShowTable(true); // Ensure table is shown
                setViewResult(true);
            } else if (statusResponse?.SyncGradeLock?.lock === null) {
                // If lock is null, set step to 2, show the modal, then call fetchCamsDetailedMarks
                setStep(2);
                await waitFor(2000);
                await dispatch(
                    fetchMarks(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType
                    )
                );
                setShowTable(true); // Ensure table is shown
                setViewResult(true);
            }
            setIsSyncModalOpen(false);
        } catch (error) {
            console.log(error, "fetchStatusCAMS error");
            try {
                await dispatch(
                    fetchGrades(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType,
                        synctype
                    )
                );
                // Call fetchStatusCAMS again after fetching grades
                const statusResponseAfterError = await dispatch(
                    fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1)
                );
                console.log(
                    statusResponseAfterError,
                    "statusResponse after fetchGradesCams in catch block"
                );

                if (statusResponseAfterError === "success") {
                    await dispatch(
                        fetchMarks(
                            selectedUniId,
                            selectLmsCourseId,
                            selectQuizId,
                            selectAssessmentId,
                            selectAssessmentOptionCheck,
                            assessmentType
                        )
                    );
                    setShowTable(true); // Ensure table is shown
                }
            } catch (fetchGradesError) {
                console.error(fetchGradesError, "fetchGradesCams error");
                setStep(3);
                await waitFor(2000);
                setStatusMessage(fetchGradesError.message);
            } finally {
                setShowTable(true);
                setIsSyncModalOpen(false);
            }
        } finally {
            clearInterval(timer);
            setIsSyncClicked(false);
        }
    };

    const handleOpenFileUploadModal = () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
        } else {
            setIsFileUploadModalOpen(true);
        }
    };

    const handleManualEntryClick = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setIsManualEntryOpen(true);
            try {
                await dispatch(
                    fetchManualTemplate(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType,
                        synctype
                    )
                );
            } catch (error) {
                console.error("Error fetching manual template:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleCloseFileUploadModal = () => {
        setIsFileUploadModalOpen(false);
    };

    const handleCloseSyncModal = () => {
        setIsSyncModalOpen(false);
    };

    const handleMarksChange = (serial, marks) => {
        const updatedData = universitiesState?.studentDeatil?.map((item) => {
            if (item.serial === serial) {
                return { ...item, marks };
            }
            return item;
        });
        setDisplayData(updatedData);
    };

    const totalPages = Math.ceil(
        universitiesState?.studentDetail?.user_grades?.length / rowsPerPage
    );
    const currentData = universitiesState?.studentDetail?.user_grades?.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const handleSelectionRadioChange = (e) => {
        const value = e.target.value;
        setSelectionType(value);
        dispatch(resetState());
    };

    const handleDownloadXl = () => {
        dispatch(
            fetchDownloadTemplate(
                selectedUniId,
                selectLmsCourseId,
                selectQuizId,
                selectAssessmentId,
                selectAssessmentOptionCheck,
                assessmentType
            )
        );
    };

    const handleReSync = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            setShowButtons(false);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            setShowButtons(false);
            try {
                // First API call
                await dispatch(
                    fetchMarks(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType
                    )
                );
                // Second API call, which will only execute after the first call completes
                await dispatch(
                    fetchGrades(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType,
                        synctype
                    )
                );
            } catch (error) {
                console.error("Error fetching manual template:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleSendToUMS = () => {
        dispatch(
            fetchSendToUms(
                selectedUniId,
                selectLmsCourseId,
                selectQuizId,
                selectAssessmentId,
                selectAssessmentOptionCheck,
                selectBatchId,
                selectCourseId,
                selectSemesterId,
                selectExamId,
                selectExamSubId,
                selectSubjectCode,
                assessmentType,
                userName,
                notify
            )
        );
    };
    const handleSendToUNI = () => {
        dispatch(
            fetchSendToUNI(
                selectedUniId,
                selectLmsCourseId,
                selectQuizId,
                selectAssessmentId,
                selectAssessmentOptionCheck,
                selectBatchId,
                selectCourseId,
                selectSemesterId,
                selectExamId,
                selectExamSubId,
                selectSubjectCode,
                assessmentType,
                userName,
                notify
            )
        );
    };

    const handleSendToUniversityClick = () => {
        dispatch(
            fetchSendToAttendance(
                selectedUniId,
                selectLmsCourseId,
                selectQuizId,
                selectAssessmentId,
                selectAssessmentOptionCheck,
                selectBatchId,
                selectCourseId,
                selectSemesterId,
                selectExamId,
                selectExamSubId,
                selectSubjectCode,
                assessmentType,
                userName,
                notify
            )
        );
    };

    useEffect(() => {
        if (selectionType === "0") {
            dispatch(fetchUniversities());
            dispatch(resetState());
        } else if (selectionType === "1") {
            dispatch(fetchUniversitiesCams());
            dispatch(resetState());
        }
    }, [dispatch, selectionType]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLock = async () => {
        try {
            // Initially call the fetchStatus API and check the status of the lock key
            let statusResponse = await dispatch(fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1));
            console.log(statusResponse, "initial statusResponse");

            // Case 2: If lock status is null, call postQuizReport and then fetchStatus to check the lock key status again
            if (universitiesState?.status?.SyncGradeLock?.lock === null) {
                await handlePostQuizReport();
                statusResponse = await dispatch(fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1));
                console.log(statusResponse, "statusResponse after postQuizReport");
            }

            // Case 3: If lock status is 1, call unlockData and then fetchStatus to update the lock key status
            if (universitiesState?.status?.SyncGradeLock?.lock === 1) {
                await handleConfirmLock();
                statusResponse = await dispatch(fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1));
                console.log(statusResponse, "statusResponse after unlockData");
            }
        } catch (error) {
            console.error("Error handling lock:", error);
        }
    };

    const handlePostQuizReport = async () => {
        try {
            await dispatch(
                postQuizReport(
                    selectedUniId,
                    selectLmsCourseId,
                    selectQuizId,
                    selectAssessmentId,
                    selectAssessmentOptionCheck,
                    assessmentType,
                    userName,
                    notify
                )
            );
            console.log("postQuizReport successful");
        } catch (error) {
            console.error("Error handling post quiz report:", error);
        }
    };

    const handleConfirmLock = async () => {
        setIsLoading(true);
        try {
            // Call unlockData API
            await dispatch(
                unlockData(
                    selectedUniId,
                    selectLmsCourseId,
                    selectQuizId,
                    selectAssessmentId,
                    assessmentType,
                    userName
                )
            );

            // Wait for 1 second to allow the SyncGradeLock status to update
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Re-check the SyncGradeLock status after unlockData
            let statusResponse = await dispatch(fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1));
            console.log(statusResponse, "statusResponse after unlockData");

            // Check if the SyncGradeLock status has been updated to null
            if (statusResponse?.SyncGradeLock?.lock === null) {
                // Close modal only after unlockData is completed
                setShowModal(false);

                // Fetch detailed marks and update UI
                await dispatch(
                    fetchMarks(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType
                    )
                );
                setShowTable(true); // Show the table
            } else {
                console.log("SyncGradeLock status not updated to null");
            }
        } catch (error) {
            console.error("Error handling confirm lock:", error);
        } finally {
            setIsLoading(false); // Ensure loading state is reset in any case
        }
    };



    const handleViewResult = async () => {
        const { hasErrors, errors } = validateFields();
        if (hasErrors) {
            setValidationErrors(errors);
            setIsLoading(false);
        } else {
            setIsLoading(true);
            try {
                const { status, data } = await dispatch(
                    fetchStatus(selectedUniId, selectLmsCourseId, selectQuizId, 1)
                );
                if (status === 404 || data === "Status not found") {
                    setViewResult(false);
                    setIsLoading(false);
                    return;
                }
                await dispatch(
                    fetchMarks(
                        selectedUniId,
                        selectLmsCourseId,
                        selectQuizId,
                        selectAssessmentId,
                        selectAssessmentOptionCheck,
                        assessmentType
                    )
                );
                setIsLoading(false);
                setViewResult(true); // Show the table after successful fetch
                setShowTable(true);
                setExpanded(false);
            } catch (error) {
                console.error("Error handling confirm lock:", error);
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="Una-home">
            <div className="sync-radio" style={{ marginBottom: "20px" }}>
                <input
                    type="radio"
                    id="University"
                    name="selectionType"
                    value="0"
                    onChange={handleSelectionRadioChange}
                    checked={selectionType === "0"}
                    style={{ width: "18px", height: "18px" }}
                />
                <label
                    htmlFor="University"
                    style={{ fontSize: "18px", color: "darkgrey" }}
                >
                    University
                </label>
                <br />
                <input
                    type="radio"
                    id="CAMS"
                    name="selectionType"
                    value="1"
                    onChange={handleSelectionRadioChange}
                    checked={selectionType === "1"}
                    style={{ width: "18px", height: "18px" }}
                />
                <label htmlFor="CAMS" style={{ fontSize: "18px", color: "darkgray" }}>
                    CAMS
                </label>
            </div>
            {selectionType === "0" && (
                <Box className="unaHome-wrappers">
                    <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleAccordionChange("panel1")}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <Typography>Select University Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className="select-box-wrapper">
                                <SelectBox
                                    label="Select a University"
                                    value={selectedUniversity}
                                    onChange={handleUniversityChange}
                                    options={optionsUniversity}
                                    className="select-section"
                                    error={validationErrors.university}
                                />
                                <SelectBox
                                    label="Select a Batches"
                                    options={optionsBatches}
                                    value={selectBatch}
                                    onChange={handleBatchChange}
                                    className="select-section"
                                    error={validationErrors.batch}
                                />
                                <SelectBox
                                    label="Select a Courses"
                                    options={optionsCourses}
                                    value={selectedCourse}
                                    onChange={handleCourseChange}
                                    className="select-section"
                                    error={validationErrors.course}
                                />
                                <SelectBox
                                    label="Select a Semester"
                                    options={optionsSemester}
                                    value={selectSem}
                                    onChange={handleSemChange}
                                    className="select-section"
                                    error={validationErrors.semester}
                                />
                                <SelectBox
                                    label="Select a Exam"
                                    options={optionsExam}
                                    value={selectExam}
                                    onChange={handleExanChange}
                                    className="select-section"
                                    error={validationErrors.exam}
                                />
                                <SelectBox
                                    label="Select a Assessment"
                                    options={optionsAssessment}
                                    value={selectAssessment}
                                    onChange={handleAssessmentType}
                                    className="select-section"
                                    error={validationErrors.assessment}
                                />
                                <SelectBox
                                    label="Select a Exam Subject"
                                    options={optionsExamSubject}
                                    value={selectExamSub}
                                    onChange={handleExamSubChange}
                                    className="select-section"
                                    error={validationErrors.examSubject}
                                />
                                <GroupSelectBox
                                    label="Select a Quiz"
                                    options={optionsQuiz}
                                    value={selectQuizId}
                                    onChange={handleQuizChange}
                                    error={validationErrors.quiz}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
            {selectionType === "1" && <Cams />}
            {selectionType === "0" && (
                <div className="sync-radio">
                    <input
                        type="radio"
                        id="online"
                        name="syncType"
                        value="0"
                        onChange={handleRadioChange}
                        defaultChecked={isOnline}
                        style={{ width: "18px", height: "18px" }}
                    />
                    <label
                        htmlFor="online"
                        style={{ fontSize: "18px", color: "darkgrey" }}
                    >
                        Online
                    </label>
                    <br />
                    <input
                        type="radio"
                        id="offline"
                        name="syncType"
                        value="1"
                        onChange={handleRadioChange}
                        style={{ width: "18px", height: "18px" }}
                    />
                    <label
                        htmlFor="offline"
                        style={{ fontSize: "18px", color: "darkgray" }}
                    >
                        Offline
                    </label>
                </div>
            )}

            {isOnline && showButtons && selectionType !== "1" && (
                <div style={{ gap: "50px", display: "flex" }}>
                    <button
                        className={`Browser-button-sync ${isLocked ? "disabled" : ""}`}
                        disabled={universitiesState?.status?.SyncGradeLock?.lock === 1}
                        onClick={handleSyncClick}
                    >
                        Sync
                    </button>

                    <button
                        className="Browser-button-view"
                        onClick={handleViewResult}
                        disabled={!isViewResult}
                    >
                        View Result
                    </button>
                </div>
            )}
            {isOffline && selectionType !== "1" && (
                <div style={{ gap: "40px", display: "flex" }}>
                    <button className="Browser-button" onClick={handleManualEntryClick}>
                        Enter Manually
                    </button>
                    <button
                        className="Browser-button"
                        onClick={handleOpenFileUploadModal}
                    >
                        Bulk Upload
                    </button>
                </div>
            )}
            {isOffline && isManualEntryOpen && (
                <div className="scrollable-div" ref={divRef}>
                    {isLoading ? (
                        <div className="loader">
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <h3>
                                Please Enter marks for B.A / Batch 1 / Semester 1 / English /
                                Internal Assessment 1
                            </h3>
                            <p>
                                Students - {universitiesState?.studentDetail?.total_records}
                            </p>
                            <table className="university-table">
                                <thead>
                                    <tr>
                                        <th>Sl No</th>
                                        <th>Enrolment Number</th>
                                        <th>Name</th>
                                        <th>Assessment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData?.map((item, index) => (
                                        <tr key={item.serial}>
                                            <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                            <td>{item.enrollmentNumber}</td>
                                            <td>{item.username}</td>
                                            <td>
                                                <input
                                                    type="number"
                                                    value={item.marks}
                                                    onChange={(e) =>
                                                        handleMarksChange(
                                                            item.serial,
                                                            parseInt(e.target.value)
                                                        )
                                                    }
                                                    style={{ width: "100px", marginRight: "20px" }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <button className="Event-button">Confirm & Submit</button>
                            <div className="pagination">
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                    shape="rounded"
                                    siblingCount={1}
                                    boundaryCount={1}
                                    showFirstButton
                                    showLastButton
                                />
                            </div>
                        </>
                    )}
                </div>
            )}

            {isOnline && showTable && selectionType === "0" && (
                <div className="scrollable-div">
                    {isLoading ? (
                        <div className="loader">
                            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                            <p>Loading...</p>
                        </div>
                    ) : (
                        <>
                            <h3>View Student Result <span></span></h3>
                            <p className="stats-container">
                                {[
                                    
                                    selectQuizId && combinedOptions.find((option) => option.value === parseInt(selectQuizId, 10))?.label && `Quiz - ${combinedOptions.find((option) => option.value === parseInt(selectQuizId, 10))?.label}`
                                ]
                                    .filter(Boolean)
                                    .map((text, index, array) => (
                                        <span key={index}>
                                            {text}
                                            {index < array.length - 1 && ', '}
                                        </span>
                                    ))}
                            </p>
                            <br />

                            <p className="stats-container">
                                {universitiesState?.marks?.total_records ? <span> Total Student - {universitiesState?.marks?.total_records}</span> : " "}
                                {universitiesState?.marks?.total_absent_records ? <span> Total Absent - {universitiesState?.marks?.total_absent_records}</span> : " "}
                                {universitiesState?.marks?.total_present_records ? <span> Total Present - {universitiesState?.marks?.total_present_records}</span> : " "}
                                {universitiesState?.marks?.total_grading_complete ? <span> Total Grading Complete - {universitiesState?.marks?.total_grading_complete}</span> : " "}
                                {universitiesState?.marks?.total_grading_incomplete !== null && universitiesState?.marks?.total_grading_incomplete !== undefined ? (
                                    <span>Total Grading Incomplete - {universitiesState?.marks?.total_grading_incomplete}</span>) : (" ")
                                }
                                {universitiesState?.marks?.submitted_count ? <span> Total Submitted Count - {universitiesState?.marks?.submitted_count}</span> : " "}
                                {universitiesState?.marks?.not_submitted_count ? <span> Total Not Submitted Count - {universitiesState?.marks?.not_submitted_count}</span> : " "}
                                {universitiesState?.marks?.submitted_grading_count ? <span> Total Submitted Grading Count - {universitiesState?.marks?.submitted_grading_count}</span> : " "}
                            </p>
                            <div className="table-container">
                                <table className="custom-table">
                                    <thead>
                                        <tr>
                                            {/* Fixed headers */}
                                            <th className="fixed fixed-col-header sr-no">Sr No</th>
                                            <th className="fixed fixed-col-header student-name">Student Name</th>
                                            <th className="fixed fixed-col-header enrollment-number">Enrollment Number</th>
                                            {/* Scrollable headers */}
                                            {Array.from({ length: maxQuestions }).map((_, i) => (
                                                <th key={`Q${i + 1}`} className="scrollable">Q{i + 1}</th>
                                            ))}
                                            {/* Fixed footer columns */}
                                            <th className="fixed">Total</th>
                                            <th className="fixed">Attendance</th>
                                            <th className="fixed">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentStudentData?.length > 0 ? (
                                            currentStudentData.map((item, index) => (
                                                <tr key={item.student_id}>
                                                    {/* Fixed columns */}
                                                    <td className="fixed fixed-col sr-no">{(currentStudentPage - 1) * rowsPerPageStudent + index + 1}</td>
                                                    <td className="fixed fixed-col student-name">{item.student_name}</td>
                                                    <td className="fixed fixed-col enrollment-number">{item.enrollment_number || '-'}</td>
                                                    {/* Scrollable cells */}
                                                    {Array.isArray(item.individual_question_marks) && item.individual_question_marks.length > 0
                                                        ? item.individual_question_marks.map((mark, markIndex) => (
                                                            <td
                                                                key={markIndex}
                                                                className="scrollable"
                                                                style={{
                                                                    backgroundColor:
                                                                        mark.question_mark === null
                                                                            ? 'white' // Blank
                                                                            : mark.question_mark === ""
                                                                                ? 'red' // Red colored cell (Grading Incomplete)
                                                                                : item.attendance_status === "Absent"
                                                                                    ? 'lightgray' // Hyphen (-) if attendance_status is Absent
                                                                                    : 'lightgray', // default color
                                                                }}
                                                            >
                                                                {mark.question_mark === null
                                                                    ? "" // Blank
                                                                    : mark.question_mark === ""
                                                                        ? "" // Red colored cell (Grading Incomplete)
                                                                        : item.attendance_status === "Absent"
                                                                            ? "-" // Hyphen (-) if attendance_status is Absent
                                                                            : mark.question_mark}
                                                            </td>
                                                        ))
                                                        : Array.from({ length: maxQuestions }).map((_, i) => (
                                                            <td key={`empty-${i}`} className="scrollable empty-cell" style={{ backgroundColor: 'white', fontSize: '20px', textAlign: 'center' }}>
                                                                -
                                                            </td>
                                                        ))}
                                                    {/* Fixed footer cells */}
                                                    <td className="fixed">{item.total_marks || '-'}</td>
                                                    <td className="fixed">{item.attendance_status}</td>
                                                    <td className="fixed" style={{ textTransform: 'capitalize' }}>{item.status || 'N/A'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                                                    No records found for the current page.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot className="fixed-footer">
                                        <tr>
                                            <td colSpan={headers.length} style={{ textAlign: 'left' }}><br />
                                                <strong>Legend:</strong>
                                                <ul className="legends-lists">
                                                    <li>Hyphen (-) = Absent</li><br />
                                                    <li>Blank = Not Attempted</li><br />
                                                    <li><span style={{ backgroundColor: 'red', padding: "1px 20px", marginRight: '5px' }}></span> = Grading Incomplete</li><br />
                                                </ul>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginBottom: "10px",
                                }}
                            >
                                <label>
                                    Records per page:
                                    <Select
                                        value={rowsPerPageStudent}
                                        onChange={handleRowsPerPageChange}
                                        style={{
                                            marginLeft: "10px",
                                            marginTop: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </label>
                            </div>

                            <div className="button-toggle-wrapper">
                                {roleName === "admin" && (
                                    <>
                                        {universitiesState?.status?.SyncGradeLock?.lock === null ? (
                                            <button className={`lock-button`} onClick={handleLock}>
                                                Lock
                                            </button>
                                        ) : (
                                            <button
                                                className={`lock-button`}
                                                onClick={handleConfirmLock}
                                            >
                                                {" "}
                                                Unlock
                                            </button>
                                        )}
                                    </>
                                )}
                                <button
                                    className={`send-to-ums-button ${isLocked ? "disabled" : ""}`}
                                    disabled={universitiesState?.status?.SyncGradeLock?.lock === null}
                                    onClick={handleSendToUMS}
                                >
                                    Send to UMS
                                </button>
                                <button
                                    className={`send-to-ums-button ${isLocked ? "disabled" : ""}`}
                                    disabled={universitiesState?.status?.SyncGradeLock?.lock === null}
                                    onClick={handleSendToUNI}
                                >
                                    Send to University
                                </button>
                                <button
                                    className={`re-sync-button ${isLocked ? "disabled" : ""}`}
                                    disabled={universitiesState?.status?.SyncGradeLock?.lock === 1}
                                    onClick={handleReSync}
                                >
                                    Re-Sync
                                </button>
                                <button className="download-button" onClick={handleDownloadXl}>
                                    Download
                                </button>
                                <button
                                    className="download-button"
                                    onClick={handleSendToUniversityClick}
                                >
                                    Send Attendance
                                </button>
                            </div>

                            <LockModal
                                show={showModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirmLock}
                            />

                            <div
                                className="pagination"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                }}
                            >
                                <Pagination
                                    count={Math.ceil(totalRecords / rowsPerPageStudent)}
                                    page={currentStudentPage}
                                    onChange={handlePageChange}
                                    color="primary"
                                    shape="rounded"
                                    siblingCount={1}
                                    boundaryCount={1}
                                    showFirstButton
                                    showLastButton
                                />
                            </div>
                        </>
                    )}
                </div>
            )}

            <SyncModal
                isOpen={isSyncModalOpen}
                onClose={handleCloseSyncModal}
                step={step}
                progress={progress}
                statusMessage={statusMessage}
            />
            <FileUploadModal
                open={isFileUploadModalOpen}
                handleClose={handleCloseFileUploadModal}
                selectLmsCourseId={selectLmsCourseId}
                selectedUniId={selectedUniId}
                selectQuizId={selectQuizId}
                selectAssessmentId={selectAssessmentId}
                selectAssessmentOptionCheck={selectAssessmentOptionCheck}
                synctype={synctype}
            />
        </div>
    );
};

export default UnaHome;
