import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  TOKEN_REQUEST,
  TOKEN_SUCCESS,
  TOKEN_FAILURE,
  UNIVERSITIES_REQUEST,
  UNIVERSITIES_SUCCESS,
  UNIVERSITIES_FAILURE,
  FETCH_BATCHES_REQUEST,
  FETCH_BATCHES_SUCCESS,
  FETCH_BATCHES_FAILURE,
  FETCH_COURSES_REQUEST,
  FETCH_COURSES_SUCCESS,
  FETCH_COURSES_FAILURE,
  FETCH_SEM_REQUEST,
  FETCH_SEM_SUCCESS,
  FETCH_SEM_FAILURE,
  FETCH_EXAM_REQUEST,
  FETCH_EXAM_SUCCESS,
  FETCH_EXAM_FAILURE,
  FETCH_EXAM_TYPE_REQUEST,
  FETCH_EXAM_TYPE_SUCCESS,
  FETCH_EXAM_TYPE_FAILURE,
  FETCH_GRADES_REQUEST,
  FETCH_GRADES_SUCCESS,
  FETCH_GRADES_FAILURE,
  FETCH_MANUAL_TEMPLATE_REQUEST,
  FETCH_MANUAL_TEMPLATE_SUCCESS,
  FETCH_MANUAL_TEMPLATE_FAILURE,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_FAILURE,
  UPLOAD_TEMPLATE_REQUEST,
  UPLOAD_TEMPLATE_SUCCESS,
  UPLOAD_TEMPLATE_FAILURE,
  UPLOAD_TEMPLATE_PROGRESS,
  FETCH_TOPICS_REQUEST,
  FETCH_TOPICS_SUCCESS,
  FETCH_TOPICS_FAILURE,
  FETCH_EXAM_SUBJECT_REQUEST,
  FETCH_EXAM_SUBJECT_SUCCESS,
  FETCH_EXAM_SUBJECT_FAILURE,
  FETCH_RESOURCES_REQUEST,
  FETCH_RESOURCES_SUCCESS,
  FETCH_RESOURCES_FAILURE,
  FETCH_MARKS_REQUEST,
  FETCH_MARKS_SUCCESS,
  FETCH_MARKS_FAILURE,
  FETCH_STORE_QUIZ_REQUEST,
  FETCH_STORE_QUIZ_SUCCESS,
  FETCH_STORE_QUIZ_FAILURE,
  FETCH_USERNAME,
  RESET_STATE,
  FETCH_UNIVERSITIES_REQUEST,
  FETCH_UNIVERSITIES_SUCCESS,
  FETCH_UNIVERSITIES_FAILURE,
  FETCH_COURSE_CATEGORIES_REQUEST,
  FETCH_COURSE_CATEGORIES_SUCCESS,
  FETCH_COURSE_CATEGORIES_FAILURE,
  POST_QUIZ_REPORT_REQUEST,
  POST_QUIZ_REPORT_SUCCESS,
  POST_QUIZ_REPORT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_FAILURE,
  FETCH_SEND_TO_UMS_REQUEST,
  FETCH_SEND_TO_UMS_SUCCESS,
  FETCH_SEND_TO_UMS_FAILURE,
  FETCH_SEND_TO_UNI_REQUEST,
  FETCH_SEND_TO_UNI_SUCCESS,
  FETCH_SEND_TO_UNI_FAILURE,
  FETCH_SEND_TO_ATT_REQUEST,
  FETCH_SEND_TO_ATT_SUCCESS,
  FETCH_SEND_TO_ATT_FAILURE,
  UNLOCK_DATA_REQUEST,
  UNLOCK_DATA_SUCCESS,
  UNLOCK_DATA_FAILURE

} from "../action/actionType";

const initialState = {
  loading: false,
  universities: [],
  courses: [],
  grades: [],
  studentDetail: [],
  token: null,
  error: null,
  finalToken: null,
  batches: [],
  username: "",
  password: "",
  semester: [],
  exam: [],
  examType: [],
  examSubject: [],
  data: null,
  topics: [],
  subject: [],
  assessment: [],
  quizzes: null,
  marks: null,
  lockQuiz: null,
  universitiesCAMS: null,
  courseCategories: null,
  quizReports: null,
  isLoggingOut: false,
  logoutError: null,
  user: null,
  status: null,
  sendToUms: null,
  sendToUni: null,
  sendToAttendance: null,
  unLock: null
};

const universitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Request actions
    case LOGIN_REQUEST:
    case TOKEN_REQUEST:
    case UNIVERSITIES_REQUEST:
    case FETCH_BATCHES_REQUEST:
    case FETCH_COURSES_REQUEST:
    case FETCH_SEM_REQUEST:
    case FETCH_EXAM_REQUEST:
    case FETCH_EXAM_TYPE_REQUEST:
    case FETCH_GRADES_REQUEST:
    case FETCH_MANUAL_TEMPLATE_REQUEST:
    case FETCH_TEMPLATE_REQUEST:
    case UPLOAD_TEMPLATE_REQUEST:
    case UPLOAD_TEMPLATE_PROGRESS:
    case FETCH_TOPICS_REQUEST:
    case FETCH_EXAM_SUBJECT_REQUEST:
    case FETCH_STORE_QUIZ_REQUEST:
      return { ...state, loading: true, error: null };
    // Success actions
    case LOGIN_SUCCESS:
      return { ...state, loading: false };
    case TOKEN_SUCCESS:
      return { ...state, loading: false, finalToken: action.payload };
    case UNIVERSITIES_SUCCESS:
      return { ...state, loading: false, universities: action.payload };
    case FETCH_BATCHES_SUCCESS:
      return { ...state, loading: false, batches: action.payload };
    case FETCH_COURSES_SUCCESS:
      return { ...state, loading: false, courses: action.payload };
    case FETCH_SEM_SUCCESS:
      return { ...state, loading: false, semester: action.payload };
    case FETCH_EXAM_SUCCESS:
      return { ...state, loading: false, exam: action.payload };
    case FETCH_EXAM_TYPE_SUCCESS:
      return { ...state, loading: false, examType: action.payload };
    case FETCH_EXAM_SUBJECT_SUCCESS:
      return { ...state, loading: false, examSubject: action.payload };
    case FETCH_GRADES_SUCCESS:
      return { ...state, loading: false, grades: action.payload };
    case FETCH_MANUAL_TEMPLATE_SUCCESS:
      return { ...state, loading: false, studentDetail: action.payload }
    case FETCH_TEMPLATE_SUCCESS:
    case UPLOAD_TEMPLATE_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case FETCH_TOPICS_SUCCESS:
      return { ...state, loading: false, topics: action.payload };
    case FETCH_STORE_QUIZ_SUCCESS:
      return { ...state, loading: false, lockQuiz: action.payload };

    case 'FETCH_ASSESSMENT_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_ASSESSMENT_SUCCESS':
      return { ...state, loading: false, assessment: action.payload };
    case 'FETCH_ASSESSMENT_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case 'FETCH_SUBJECTS_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_SUBJECTS_SUCCESS':
      return { ...state, loading: false, subject: action.payload };
    case 'FETCH_SUBJECTS_FAILURE':
      return { ...state, loading: false, error: action.payload };
    case FETCH_RESOURCES_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_RESOURCES_SUCCESS:
      return { ...state, loading: false, quizzes: action.payload, error: null };
    case FETCH_RESOURCES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_MARKS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        marks: action.payload,
        error: ''
      };
    case FETCH_MARKS_FAILURE:
      return {
        ...state,
        loading: false,
        marks: [],
        error: action.payload
      };
    case FETCH_UNIVERSITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_UNIVERSITIES_SUCCESS:
      return {
        loading: false,
        universitiesCAMS: action.payload,
        error: ''
      };
    case FETCH_UNIVERSITIES_FAILURE:
      return {
        loading: false,
        universitiesCAMS: [],
        error: action.payload
      };
    case FETCH_COURSE_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_COURSE_CATEGORIES_SUCCESS:
      return {
        loading: false,
        courseCategories: action.payload,
        error: ''
      };
    case FETCH_COURSE_CATEGORIES_FAILURE:
      return {
        loading: false,
        courseCategories: [],
        error: action.payload
      };
    case POST_QUIZ_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_QUIZ_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        quizReports: action.payload,
      };
    case POST_QUIZ_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: action.payload,
      };
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        role: action.payload.role,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: null
      };
    case FETCH_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case FETCH_SEND_TO_UMS_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SEND_TO_UMS_SUCCESS:
      return { ...state, loading: false, sendToUms: action.payload };
    case FETCH_SEND_TO_UMS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_SEND_TO_UNI_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SEND_TO_UNI_SUCCESS:
      return { ...state, loading: false, sendToUni: action.payload };
    case FETCH_SEND_TO_UNI_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FETCH_SEND_TO_ATT_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_SEND_TO_ATT_SUCCESS:
      return { ...state, loading: false, sendToAttendance: action.payload };
    case FETCH_SEND_TO_ATT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UNLOCK_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case UNLOCK_DATA_SUCCESS:
      return { ...state, loading: false, unLock: action.payload };
    case UNLOCK_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Failure actions
    case LOGIN_FAILURE:
    case TOKEN_FAILURE:
    case UNIVERSITIES_FAILURE:
    case FETCH_BATCHES_FAILURE:
    case FETCH_COURSES_FAILURE:
    case FETCH_SEM_FAILURE:
    case FETCH_EXAM_FAILURE:
    case FETCH_EXAM_TYPE_FAILURE:
    case FETCH_GRADES_FAILURE:
    case FETCH_MANUAL_TEMPLATE_FAILURE:
    case FETCH_TEMPLATE_FAILURE:
    case UPLOAD_TEMPLATE_FAILURE:
    case FETCH_EXAM_SUBJECT_FAILURE:
    case FETCH_TOPICS_FAILURE:
    case FETCH_STORE_QUIZ_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_USERNAME:
      return { ...state, password: action.payload.password, username: action.payload.username };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default universitiesReducer;
