import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import './selectBox.scss';

const SelectBox = ({ label, value, onChange, options, className, error, required }) => {
  return (
    <FormControl fullWidth variant="outlined" className={className} error={!!error}>
      <InputLabel>
      {required && <span className="required" style={{color:'red'}}> * </span>}
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
      >
        {options.length > 0 ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>No options available</MenuItem>
        )}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBox;
