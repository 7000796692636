import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";

const GroupSelectBox = ({
  label,
  value,
  onChange,
  options,
  className,
  error,
  required,
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={className}
      style={{ marginTop: '20px' }}
      error={!!error}
    >
      <InputLabel htmlFor="grouped-native-select">
        {required && (
          <span className="required" style={{ color: "red" }}>
            *
          </span>
        )}
        {label}
      </InputLabel>
      <Select
        native
        value={value}
        id="grouped-native-select"
        onChange={onChange}
        label={label}
      >
        {options &&
          Object.keys(options).length > 0 &&
          Object.keys(options).map((groupKey) => (
            <React.Fragment key={groupKey}>
              {groupKey.length > 0 && (
                <optgroup label={groupKey} style={{textTransform:'capitalize'}}>
                  {Array.isArray(options[groupKey]) && options[groupKey].map((option) => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </optgroup>
              )}
            </React.Fragment>
          ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default GroupSelectBox;
