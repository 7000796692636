// src/actions/actionTypes.js
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';
export const ERROR = 'ERROR';
export const UNIVERSITIES_REQUEST = 'UNIVERSITIES_REQUEST';
export const UNIVERSITIES_SUCCESS = 'UNIVERSITIES_SUCCESS';
export const UNIVERSITIES_FAILURE = 'UNIVERSITIES_FAILURE';
export const FETCH_BATCHES_REQUEST = 'FETCH_BATCHES_REQUEST';
export const FETCH_BATCHES_SUCCESS = 'FETCH_BATCHES_SUCCESS';
export const FETCH_BATCHES_FAILURE = 'FETCH_BATCHES_FAILURE';
export const FETCH_COURSES_REQUEST = 'FETCH_COURSES_REQUEST';
export const FETCH_COURSES_SUCCESS = 'FETCH_COURSES_SUCCESS';
export const FETCH_COURSES_FAILURE = 'FETCH_COURSES_FAILURE';
export const FETCH_SEM_REQUEST = 'FETCH_SEM_REQUEST';
export const FETCH_SEM_SUCCESS = 'FETCH_SEM_SUCCESS';
export const FETCH_SEM_FAILURE = 'FETCH_SEM_FAILURE';
export const FETCH_EXAM_REQUEST = 'FETCH_EXAM_REQUEST';
export const FETCH_EXAM_SUCCESS = 'FETCH_EXAM_SUCCESS';
export const FETCH_EXAM_FAILURE = 'FETCH_EXAM_FAILURE';
export const FETCH_EXAM_TYPE_REQUEST = 'FETCH_EXAM_TYPE_REQUEST';
export const FETCH_EXAM_TYPE_SUCCESS = 'FETCH_EXAM_TYPE_SUCCESS';
export const FETCH_EXAM_TYPE_FAILURE = 'FETCH_EXAM_TYPE_FAILURE';
export const FETCH_GRADES_REQUEST = 'FETCH_GRADES_REQUEST';
export const FETCH_GRADES_SUCCESS = 'FETCH_GRADES_SUCCESS';
export const FETCH_GRADES_FAILURE = 'FETCH_GRADES_FAILURE';
export const FETCH_MANUAL_TEMPLATE_REQUEST = 'FETCH_MANUAL_TEMPLATE_REQUEST';
export const FETCH_MANUAL_TEMPLATE_SUCCESS = 'FETCH_MANUAL_TEMPLATE_SUCCESS';
export const FETCH_MANUAL_TEMPLATE_FAILURE = 'FETCH_MANUAL_TEMPLATE_FAILURE';
export const FETCH_TEMPLATE_REQUEST = 'FETCH_TEMPLATE_REQUEST';
export const FETCH_TEMPLATE_SUCCESS = 'FETCH_TEMPLATE_SUCCESS';
export const FETCH_TEMPLATE_FAILURE = 'FETCH_TEMPLATE_FAILURE';
export const UPLOAD_TEMPLATE_REQUEST = 'UPLOAD_TEMPLATE_REQUEST';
export const UPLOAD_TEMPLATE_SUCCESS = 'UPLOAD_TEMPLATE_SUCCESS';
export const UPLOAD_TEMPLATE_FAILURE = 'UPLOAD_TEMPLATE_FAILURE';
export const UPLOAD_TEMPLATE_PROGRESS = 'UPLOAD_TEMPLATE_PROGRESS';
export const FETCH_TOPICS_REQUEST = 'FETCH_TOPICS_REQUEST';
export const FETCH_TOPICS_SUCCESS = 'FETCH_TOPICS_SUCCESS';
export const FETCH_TOPICS_FAILURE = 'FETCH_TOPICS_FAILURE';
export const FETCH_EXAM_SUBJECT_REQUEST = 'FETCH_EXAM_SUBJECT_REQUEST';
export const FETCH_EXAM_SUBJECT_SUCCESS = 'FETCH_EXAM_SUBJECT_SUCCESS';
export const FETCH_EXAM_SUBJECT_FAILURE = 'FETCH_EXAM_SUBJECT_FAILURE';
export const FETCH_RESOURCES_REQUEST = 'FETCH_RESOURCES_REQUEST';
export const FETCH_RESOURCES_SUCCESS = 'FETCH_RESOURCES_SUCCESS';
export const FETCH_RESOURCES_FAILURE = 'FETCH_RESOURCES_FAILURE';
export const FETCH_MARKS_REQUEST = 'FETCH_MARKS_REQUEST';
export const FETCH_MARKS_SUCCESS = 'FETCH_MARKS_SUCCESS';
export const FETCH_MARKS_FAILURE = 'FETCH_MARKS_FAILURE';
export const FETCH_STORE_QUIZ_REQUEST = 'FETCH_STORE_QUIZ_REQUEST';
export const FETCH_STORE_QUIZ_SUCCESS = 'FETCH_STORE_QUIZ_SUCCESS';
export const FETCH_STORE_QUIZ_FAILURE = 'FETCH_STORE_QUIZ_FAILURE';
export const RESET_STATE = 'RESET_STATE';
export const FETCH_USERNAME = 'FETCH_USERNAME';
// actionTypes.js
export const FETCH_UNIVERSITIES_REQUEST = 'FETCH_UNIVERSITIES_REQUEST';
export const FETCH_UNIVERSITIES_SUCCESS = 'FETCH_UNIVERSITIES_SUCCESS';
export const FETCH_UNIVERSITIES_FAILURE = 'FETCH_UNIVERSITIES_FAILURE';
// actionTypes.js
export const FETCH_COURSE_CATEGORIES_REQUEST = 'FETCH_COURSE_CATEGORIES_REQUEST';
export const FETCH_COURSE_CATEGORIES_SUCCESS = 'FETCH_COURSE_CATEGORIES_SUCCESS';
export const FETCH_COURSE_CATEGORIES_FAILURE = 'FETCH_COURSE_CATEGORIES_FAILURE';

export const FETCH_COURSES_REQUEST_CAMS = 'FETCH_COURSES_REQUEST_CAMS';
export const FETCH_COURSES_SUCCESS_CAMS = 'FETCH_COURSES_SUCCESS_CAMS';
export const FETCH_COURSES_FAILURE_CAMS = 'FETCH_COURSES_FAILURE_CAMS';

export const FETCH_UNIVERSITIES_CAMS_REQUEST = 'FETCH_UNIVERSITIES_CAMS_REQUEST';
export const FETCH_UNIVERSITIES_CAMS_SUCCESS = 'FETCH_UNIVERSITIES_CAMS_SUCCESS';
export const FETCH_UNIVERSITIES_CAMS_FAILURE = 'FETCH_UNIVERSITIES_CAMS_FAILURE';


export const FETCH_CAMS_RESOURCES_REQUEST = 'FETCH_CAMS_RESOURCES_REQUEST';
export const FETCH_CAMS_RESOURCES_SUCCESS = 'FETCH_CAMS_RESOURCES_SUCCESS';
export const FETCH_CAMS_RESOURCES_FAILURE = 'FETCH_CAMS_RESOURCES_FAILURE';

export const FETCH_GRADES_REQUEST_CAM = 'FETCH_GRADES_REQUEST_CAM';
export const FETCH_GRADES_SUCCESS_CAM = 'FETCH_GRADES_SUCCESS_CAM';
export const FETCH_GRADES_FAILURE_CAM = 'FETCH_GRADES_FAILURE_CAM';

export const FETCH_CAMS_DETAILED_MARKS_REQUEST = 'FETCH_CAMS_DETAILED_MARKS_REQUEST';
export const FETCH_CAMS_DETAILED_MARKS_SUCCESS = 'FETCH_CAMS_DETAILED_MARKS_SUCCESS';
export const FETCH_CAMS_DETAILED_MARKS_FAILURE = 'FETCH_CAMS_DETAILED_MARKS_FAILURE';


// src/redux/actionTypes.js
export const FETCH_QUIZ_REPORT_REQUEST = 'FETCH_QUIZ_REPORT_REQUEST';
export const FETCH_QUIZ_REPORT_SUCCESS = 'FETCH_QUIZ_REPORT_SUCCESS';
export const FETCH_QUIZ_REPORT_FAILURE = 'FETCH_QUIZ_REPORT_FAILURE';

export const POST_QUIZ_REPORT_REQUEST = 'POST_QUIZ_REPORT_REQUEST';
export const POST_QUIZ_REPORT_SUCCESS = 'POST_QUIZ_REPORT_SUCCESS';
export const POST_QUIZ_REPORT_FAILURE = 'POST_QUIZ_REPORT_FAILURE';



export const POST_QUIZ_REPORT_REQUEST_CAMS = 'POST_QUIZ_REPORT_REQUEST_CAMS';
export const POST_QUIZ_REPORT_SUCCESS_CAMS = 'POST_QUIZ_REPORT_SUCCESS_CAMS';
export const POST_QUIZ_REPORT_FAILURE_CAMS = 'POST_QUIZ_REPORT_FAILURE_CAMS';

export const  LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_STATUS_REQUEST = 'FETCH_STATUS_REQUEST';
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS';
export const FETCH_STATUS_FAILURE = 'FETCH_STATUS_FAILURE';

export const FETCH_STATUS_REQUEST_CAM = 'FETCH_STATUS_REQUEST_CAM';
export const FETCH_STATUS_SUCCESS_CAM = 'FETCH_STATUS_SUCCESS_CAM';
export const FETCH_STATUS_FAILURE_CAM = 'FETCH_STATUS_FAILURE_CAM';

export const FETCH_SEND_TO_UMS_REQUEST = 'FETCH_SEND_TO_UMS_REQUEST';
export const FETCH_SEND_TO_UMS_SUCCESS = 'FETCH_SEND_TO_UMS_SUCCESS';
export const FETCH_SEND_TO_UMS_FAILURE = 'FETCH_SEND_TO_UMS_FAILURE';

export const FETCH_CAMS_SEND_TO_UMS_REQUEST = 'FETCH_CAMS_SEND_TO_UMS_REQUEST';
export const FETCH_CAMS_SEND_TO_UMS_SUCCESS = 'FETCH_CAMS_SEND_TO_UMS_SUCCESS';
export const FETCH_CAMS_SEND_TO_UMS_FAILURE = 'FETCH_CAMS_SEND_TO_UMS_FAILURE';

export const CAMS_UNLOCK_DATA_REQUEST = 'CAMS_UNLOCK_DATA_REQUEST';
export const CAMS_UNLOCK_DATA_SUCCESS = 'CAMS_UNLOCK_DATA_SUCCESS';
export const CAMS_UNLOCK_DATA_FAILURE = 'CAMS_UNLOCK_DATA_FAILURE';

export const CAMS_ATTENDANCE_REQUEST = 'CAMS_ATTENDANCE_REQUEST';
export const CAMS_ATTENDANCE_SUCCESS = 'CAMS_ATTENDANCE_SUCCESS';
export const CAMS_ATTENDANCE_FAILURE = 'CAMS_ATTENDANCE_FAILURE';


export const FETCH_CAMS_SEND_TO_UNS_REQUEST = 'FETCH_CAMS_SEND_TO_UNS_REQUEST';
export const FETCH_CAMS_SEND_TO_UNS_SUCCESS = 'FETCH_CAMS_SEND_TO_UNS_SUCCESS';
export const FETCH_CAMS_SEND_TO_UNS_FAILURE = 'FETCH_CAMS_SEND_TO_UNS_FAILURE';


export const FETCH_SEND_TO_UNI_REQUEST = 'FETCH_SEND_TO_UNI_REQUEST';
export const FETCH_SEND_TO_UNI_SUCCESS = 'FETCH_SEND_TO_UNI_SUCCESS';
export const FETCH_SEND_TO_UNI_FAILURE = 'FETCH_SEND_TO_UNI_FAILURE';

export const FETCH_SEND_TO_ATT_REQUEST = 'FETCH_SEND_TO_ATT_REQUEST';
export const FETCH_SEND_TO_ATT_SUCCESS = 'FETCH_SEND_TO_ATT_SUCCESS';
export const FETCH_SEND_TO_ATT_FAILURE = 'FETCH_SEND_TO_ATT_FAILURE';


export const UNLOCK_DATA_REQUEST = 'UNLOCK_DATA_REQUEST';
export const UNLOCK_DATA_SUCCESS = 'UNLOCK_DATA_SUCCESS';
export const UNLOCK_DATA_FAILURE = 'UNLOCK_DATA_FAILURE';


